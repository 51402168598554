import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faDumbbell,
	faChevronDown,
	faTrophy,
	faShieldHalved,
	faChartLine,
	faArrowRight,
	faRankingStar,
	faBrain,
	faMedal,
	faVideo,
	faUsers,
	faShare,
	faChartPie,
	faSignInAlt,
	faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
	faFacebook,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const ScienceOfCombatFitness = () => {
	useEffect(() => {
		Scroll.animateScroll.scrollToTop();
	}, []);

	return (
		<div className="min-h-screen bg-zinc-900 text-white font-sans">
			{/* Navigation Bar */}
			<nav className="fixed top-0 w-full bg-black/80 backdrop-blur-md z-50">
				<div className="container mx-auto px-4 sm:px-6 py-4">
					<div className="flex justify-between items-center">
						<div className="flex items-center space-x-8 sm:space-x-16">
							<img src="logo.svg" alt="Logo" className="h-8 sm:h-12 w-auto" />
						</div>
						<div className="flex items-center space-x-4 sm:space-x-6">
							<Link
								to="/login"
								className="text-gray-300 hover:text-white transition-colors duration-300 flex items-center space-x-2 group">
								<FontAwesomeIcon
									icon={faSignInAlt}
									className="group-hover:rotate-12 transition-transform duration-300"
								/>
								<span>Login</span>
							</Link>
							<Link
								to="/register"
								className="bg-primary hover:bg-primary/30 px-6 py-2 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center space-x-2 shadow-lg shadow-primary/20">
								<FontAwesomeIcon
									icon={faUserPlus}
									className="group-hover:scale-110 transition-transform duration-300"
								/>
								<span>Get Started</span>
							</Link>
						</div>
					</div>
				</div>
			</nav>
			{/* Hero Section */}
			<section className="relative h-screen flex items-center">
				{/* Background Image */}
				<div className="absolute inset-0 z-0">
					<img
						src="/static/mma/index.jpg"
						alt="MMA Training"
						className="w-full h-full object-cover"
					/>
					<div className="absolute inset-0 bg-gradient-to-r from-zinc-900 via-zinc-900/85 to-zinc-900/50" />
				</div>

				{/* Content */}
				<div className="container mx-auto px-4 sm:px-6 relative z-10 py-12 md:py-20">
					<div className="max-w-4xl space-y-4 sm:space-y-6 md:space-y-8">
						<h1
							className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 
							font-bold tracking-tight leading-[1.1] 
							bg-gradient-to-r from-primary-light to-primary-dark 
							bg-clip-text text-white animate-fade-in">
							Applying Fitness Science to the Adrenaline Blue Level Belt system
						</h1>
						<p className="text-xl md:text-2xl text-gray-300 leading-relaxed mx-auto animate-fade-in-up">
							Adrenaline Combat Fitness integrates cutting-edge fitness science,
							drawn from peer reviewed journals, into its training regime to
							provide unique, high intensity fitness training.
						</p>
					</div>
				</div>

				{/* Scroll Indicator */}
				<div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 animate-bounce">
					<FontAwesomeIcon
						icon={faChevronDown}
						className="text-primary-light text-2xl"
					/>
				</div>
			</section>

			{/* Training Components */}
			<section className="py-16 bg-zinc-800/30">
				<div className="container mx-auto px-6">
					{/* Main Content */}
					<div className="mx-auto">
						{/* Section Header */}
						{/* Main Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5">
							<h2 className="text-4xl font-bold bg-clip-text text-center text-lightShade bg-gradient-to-r from-primary to-primary-light">
								Science-Based Training Components
							</h2>
						</div>
						{/* First Section: Calisthenics */}
						<div className="mb-16">
							{/* Section Header */}
							<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
								<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
								<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
								<h3 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
									A. Calisthenics & Bodyweight Training
								</h3>
								<p className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] lg:text-[1.25rem] text-gray-300 leading-relaxed">
									Calisthenics and bodyweight exercises form the foundation of
									combat fitness by improving strength, endurance, and
									flexibility without the need for heavy equipment. Research
									supports the idea that progressive calisthenics training can
									match traditional weightlifting in strength development. At
									Adrenaline MMA, the following exercises are key elements of
									training:
								</p>
							</div>
							<div className="grid lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-10">
								{[
									{
										title: "Calisthenics",
										icon: faDumbbell,
										items: [
											{
												name: "Push-ups & Dips",
												details:
													"3 sets of 15-20 reps, focusing on explosive power",
												benefits:
													"Builds striking power and grappling strength",
											},
											{
												name: "Squats & Lunges",
												details: "4 sets of 12-15 reps per leg",
												benefits: "Develops explosive leg power for kicks",
											},
											{
												name: "Planks & Core Drills",
												details: "3-5 minute holds with variations",
												benefits: "Enhances striking stability and balance",
											},
										],
									},
								].map((component, index) => (
									<div
										key={index}
										className="bg-zinc-800/30 rounded-xl sm:rounded-2xl p-6 sm:p-8 lg:p-10 
                         border border-zinc-700/50 hover:border-primary-light/50 
                         transition-all duration-300">
										<div className="flex items-center gap-3 sm:gap-4 lg:gap-5 mb-6 sm:mb-8 lg:mb-10">
											<div
												className="p-3 sm:p-4 bg-gradient-to-br from-primary-dark/20 to-primary-light/20 
                               rounded-lg sm:rounded-xl">
												<FontAwesomeIcon
													icon={component.icon}
													className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide"
												/>
											</div>
											<h4 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
												{component.title}
											</h4>
										</div>

										<div className="space-y-4 sm:space-y-6 lg:space-y-8">
											{component.items.map((item, idx) => (
												<div
													key={idx}
													className="bg-zinc-800/50 rounded-lg sm:rounded-xl p-4 sm:p-6 lg:p-8
                               hover:bg-zinc-700/30 transition-all duration-300">
													<h5
														className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                                   font-semibold text-primary-light mb-2 sm:mb-3">
														{item.name}
													</h5>
													<p
														className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                                  text-gray-400 mb-2 leading-relaxed">
														{item.details}
													</p>
													<p
														className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                                  text-gray-300 leading-relaxed">
														{item.benefits}
													</p>
												</div>
											))}
										</div>
									</div>
								))}

								{/* Image Side */}
								<div className="flex-1 mt-8 lg:mt-0">
									<div className="sticky top-24 rounded-xl sm:rounded-2xl overflow-hidden">
										<img
											src="/static/mma/tp-16.jpg"
											alt="Calisthenics Training"
											className="w-full h-[600px] sm:h-[700px] md:h-[800px] object-cover object-[center_top]"
										/>
										<div className="absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/50 to-transparent" />

										{/* Stats Overlay */}
										<div
											className="absolute bottom-4 sm:bottom-8 left-4 sm:left-8 right-4 sm:right-8 
                          bg-zinc-900/80 backdrop-blur-sm rounded-lg sm:rounded-xl p-4 sm:p-6 lg:p-8 
                          border border-zinc-700/50">
											<div className="grid grid-cols-2 gap-4 sm:gap-6 lg:gap-8">
												<div>
													<div
														className="text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] 
                                font-bold text-primary-light mb-1 sm:mb-2">
														51%
													</div>
													<div className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400">
														Increase in Push-up Capacity
													</div>
												</div>
												<div>
													<div
														className="text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] 
                                font-bold text-primary-light mb-1 sm:mb-2">
														56%
													</div>
													<div className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400">
														Improvement in Core Strength
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-16">
							{/* Section Header */}
							<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
								<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
								<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
								<h3 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
									B. Core Strengthening for MMA Performance
								</h3>
								<p className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] lg:text-[1.25rem] text-gray-300 leading-relaxed">
									Core stability is crucial in MMA for rotational power,
									balance, and injury prevention. Studies have shown that
									military recruits integrating core exercises reduced ACL
									injuries by 30%. Adrenaline MMA Gym incorporates:
								</p>
							</div>

							{/* 2-column grid */}
							<div className="grid lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-10">
								{/* Content Card */}
								<div
									className="bg-zinc-800/30 rounded-xl sm:rounded-2xl p-6 sm:p-8 lg:p-10 
                        border border-zinc-700/50 hover:border-primary-light/50 transition-all duration-300">
									<div className="flex items-center gap-3 sm:gap-4 lg:gap-5 mb-6 sm:mb-8 lg:mb-10">
										<div className="p-3 sm:p-4 bg-gradient-to-br from-primary-dark/20 to-primary-light/20 rounded-lg sm:rounded-xl">
											<FontAwesomeIcon
												icon={faShieldHalved}
												className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide"
											/>
										</div>
										<h4 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
											Core Training
										</h4>
									</div>

									<div className="space-y-4 sm:space-y-6 lg:space-y-8">
										{[
											{
												name: "Medicine ball slams & Russian twists",
												details: "for rotational power",
											},
											{
												name: "Hanging leg raises & stability ball exercises",
												details: "for ab and lower-back engagement",
											},
											{
												name: "Bridging drills & functional hip work",
												details: "for grappling endurance",
											},
										].map((item, idx) => (
											<div
												key={idx}
												className="bg-zinc-800/50 rounded-lg sm:rounded-xl p-4 sm:p-6 lg:p-8
                                    hover:bg-zinc-700/30 transition-all duration-300">
												<h5
													className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                                        font-semibold text-primary-light mb-2 sm:mb-3">
													{item.name}
												</h5>
												<p
													className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                                        text-gray-300 leading-relaxed">
													{item.details}
												</p>
											</div>
										))}
									</div>
								</div>

								{/* Image Card */}
								<div className="bg-zinc-800/30 rounded-xl sm:rounded-2xl overflow-hidden border border-zinc-700/50">
									<div className="relative h-full">
										<img
											src="/static/mma/tp-15.jpg"
											alt="Core Training"
											className="w-full h-full object-cover"
										/>
										<div className="absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/50 to-transparent" />

										{/* Stats Overlay */}
										<div
											className="absolute bottom-4 sm:bottom-6 left-4 sm:left-6 right-4 sm:right-6 
                                bg-zinc-900/80 backdrop-blur-sm rounded-lg sm:rounded-xl p-4 sm:p-6 
                                border border-zinc-700/50">
											<div className="grid grid-cols-2 gap-4 sm:gap-6">
												<div>
													<div className="text-xl sm:text-2xl font-bold text-primary-light mb-2">
														30%
													</div>
													<div className="text-sm sm:text-base text-gray-400">
														Reduction in ACL Injuries
													</div>
												</div>
												<div>
													<div className="text-xl sm:text-2xl font-bold text-primary-light mb-2">
														40%
													</div>
													<div className="text-sm sm:text-base text-gray-400">
														Increase in Core Strength
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Training Components */}
			<section className="py-16">
				<div className="container mx-auto px-6">
					{/* Main Content */}
					<div className="mx-auto">
						{/* Section Header */}
						{/* Main Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5">
							<h2 className="text-4xl font-bold bg-clip-text text-center text-lightShade bg-gradient-to-r from-primary to-primary-light">
								High-Intensity Functional Training (HIFT) and MMA-Specific
								Movement
							</h2>
						</div>
						{/* First Section: Calisthenics */}
						<div className="mb-16">
							{/* Section Header */}
							<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
								<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
								<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
								<h3 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
									C. High-Intensity Functional Training (HIFT)
								</h3>
								<p className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] lg:text-[1.25rem] text-gray-300 leading-relaxed">
									Adrenaline MMA employs HIIT-based combat fitness to maximize
									conditioning while mimicking real fight scenarios. Research
									shows that HIIT workouts can improve VO₂ max by 12% in combat
									athletes. Combat-Fitness HIFT routines include:
								</p>
							</div>
							<div className="grid lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-10">
								{[
									{
										title: "Combat HIFT Components",
										icon: faDumbbell,
										items: [
											{
												name: "Bag Work & Pad Drills",
												details: "Simulating fight endurance",
												benefits: "Builds real-fight conditioning",
											},
											{
												name: "Explosive Plyometrics",
												details: "Jump squats and burpees for speed",
												benefits: "Develops explosive power",
											},
											{
												name: "Short-Rest Sparring Rounds",
												details: "Building fight-specific endurance",
												benefits: "Enhances combat stamina",
											},
										],
									},
								].map((component, index) => (
									<div
										key={index}
										className="bg-zinc-800/30 rounded-xl sm:rounded-2xl p-6 sm:p-8 lg:p-10 
                         border border-zinc-700/50 hover:border-primary-light/50 transition-all duration-300">
										<div className="flex items-center gap-3 sm:gap-4 mb-6 sm:mb-8">
											<div className="p-3 sm:p-4 bg-gradient-to-br from-primary-dark/20 to-primary-light/20 rounded-xl">
												<FontAwesomeIcon
													icon={component.icon}
													className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide"
												/>
											</div>
											<h4 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
												{component.title}
											</h4>
										</div>

										<div className="space-y-4 sm:space-y-6">
											{component.items.map((item, idx) => (
												<div
													key={idx}
													className="bg-zinc-800/50 rounded-xl p-4 sm:p-6 hover:bg-zinc-700/30 
                               transition-all duration-300">
													<h5
														className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                                   font-semibold text-primary-light mb-2 sm:mb-3">
														{item.name}
													</h5>
													<p className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400 mb-2">
														{item.details}
													</p>
													<p className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-300">
														{item.benefits}
													</p>
												</div>
											))}
										</div>
									</div>
								))}

								{/* Image Side */}
								<div className="flex-1 mt-8 lg:mt-0">
									<div className="sticky top-24 rounded-xl sm:rounded-2xl overflow-hidden">
										<img
											src="/static/mma/tp-14.jpg"
											alt="Calisthenics Training"
											className="w-full h-[600px] sm:h-[700px] md:h-[800px] object-cover"
										/>
										<div className="absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/50 to-transparent" />

										{/* Stats Overlay */}
										<div
											className="absolute bottom-4 sm:bottom-8 left-4 sm:left-8 right-4 sm:right-8 
                          bg-zinc-900/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 
                          border border-zinc-700/50">
											<div className="grid grid-cols-2 gap-4 sm:gap-6">
												<div>
													<div
														className="text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] 
                                font-bold text-primary-light mb-1 sm:mb-2">
														51%
													</div>
													<div className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400">
														Increase in Push-up Capacity
													</div>
												</div>
												<div>
													<div
														className="text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] 
                                font-bold text-primary-light mb-1 sm:mb-2">
														56%
													</div>
													<div className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400">
														Improvement in Core Strength
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Section B */}
					<div className="mt-16">
						{/* Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
							<h3 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								D. MMA-Specific Movement & Shadow Boxing
							</h3>
							<p className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] lg:text-[1.25rem] text-gray-300 leading-relaxed">
								Footwork and defensive movement drills play a critical role in
								fight performance. Shadowboxing burns approximately 400 calories
								per hour while refining technique and coordination. The
								Adrenaline Blue Level system ensures structured footwork drills:
							</p>
						</div>

						<div className="grid lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-10">
							{[
								{
									title: "Movement Training",
									icon: faShieldHalved,
									items: [
										{
											name: "Forward, backward, lateral movement drills",
											details: "to maintain stance stability",
											benefits: "Builds foundational movement patterns",
										},
										{
											name: "Feints, level changes, and slips",
											details: "to evade strikes",
											benefits: "Develops defensive movement skills",
										},
										{
											name: "Combination striking drills",
											details: "that reinforce movement integration",
											benefits: "Enhances movement-strike coordination",
										},
									],
								},
							].map((component, index) => (
								<div
									key={index}
									className="bg-zinc-800/30 rounded-xl sm:rounded-2xl p-6 sm:p-8 lg:p-10 
                     border border-zinc-700/50 hover:border-primary-light/50 
                     transition-all duration-300">
									<div className="flex items-center gap-3 sm:gap-4 mb-6 sm:mb-8">
										<div className="p-3 sm:p-4 bg-gradient-to-br from-primary-dark/20 to-primary-light/20 rounded-xl">
											<FontAwesomeIcon
												icon={component.icon}
												className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide"
											/>
										</div>
										<h4 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
											{component.title}
										</h4>
									</div>

									<div className="space-y-4 sm:space-y-6">
										{component.items.map((item, idx) => (
											<div
												key={idx}
												className="bg-zinc-800/50 rounded-xl p-4 sm:p-6 hover:bg-zinc-700/30 
                           transition-all duration-300">
												<h5
													className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                               font-semibold text-primary-light mb-2 sm:mb-3">
													{item.name}
												</h5>
												<p
													className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                              text-gray-400 mb-2">
													{item.details}
												</p>
												<p
													className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                              text-gray-300">
													{item.benefits}
												</p>
											</div>
										))}
									</div>
								</div>
							))}
							{/* Image Side */}
							<div className="bg-zinc-800/30 rounded-xl sm:rounded-2xl overflow-hidden border border-zinc-700/50">
								<div className="relative h-full">
									<img
										src="/static/mma/tp-8.jpg"
										alt="Core Training"
										className="w-full h-[600px] sm:h-[700px] md:h-[800px] object-cover"
									/>
									<div className="absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/50 to-transparent" />

									{/* Stats Overlay */}
									<div
										className="absolute bottom-4 sm:bottom-8 left-4 sm:left-8 right-4 sm:right-8 
                      bg-zinc-900/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 lg:p-8
                      border border-zinc-700/50">
										<div className="grid grid-cols-2 gap-4 sm:gap-6">
											<div>
												<div
													className="text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] 
                            font-bold text-primary-light mb-1 sm:mb-2">
													30%
												</div>
												<div className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400">
													Improved Striking Power
												</div>
											</div>
											<div>
												<div
													className="text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] 
                            font-bold text-primary-light mb-1 sm:mb-2">
													40%
												</div>
												<div className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400">
													Better Balance Control
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Blue Level System */}
			<section className="py-16 sm:py-24 md:py-32 bg-zinc-800/30">
				<div className="container mx-auto px-4 sm:px-6 lg:px-8">
					<div className="relative mb-8 md:mb-16 pl-4 md:pl-5">
						<h2 className="text-4xl font-bold bg-clip-text text-center text-lightShade bg-gradient-to-r from-primary to-primary-light">
							Blue Level Lessons & Progression
						</h2>
					</div>

					<div className="grid lg:grid-cols-2 gap-8 sm:gap-12 lg:gap-16">
						{/* Left Column - Image */}
						<div className="relative">
							<div className="sticky top-24 rounded-xl sm:rounded-2xl overflow-hidden">
								<img
									src="/static/mma/mark.png"
									alt="Blue Level Training"
									className="w-full h-[600px] sm:h-[700px] md:h-[800px] lg:h-[900px] object-cover"
								/>
								<div className="absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/50 to-transparent" />

								{/* Stats Cards */}
								<div
									className="absolute top-4 sm:top-6 md:top-8 left-4 sm:left-6 md:left-8 
                         right-4 sm:right-6 md:right-8 flex justify-between gap-4">
									<div
										className="bg-zinc-900/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 
                          border border-zinc-700/50 flex-1">
										<div
											className="text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] 
                            font-bold text-primary-light">
											12+
										</div>
										<div className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400">
											Training Modules
										</div>
									</div>
									<div
										className="bg-zinc-900/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 
                          border border-zinc-700/50 flex-1">
										<div
											className="text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] 
                            font-bold text-primary-light">
											45+
										</div>
										<div className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-400">
											Training Hours
										</div>
									</div>
								</div>

								{/* Info Card */}
								<div
									className="absolute bottom-4 sm:bottom-6 md:bottom-8 
                         left-4 sm:left-6 md:left-8 right-4 sm:right-6 md:right-8 
                         bg-zinc-900/80 backdrop-blur-sm rounded-xl p-4 sm:p-6 
                         border border-zinc-700/50">
									<h3
										className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                         font-semibold text-primary-light mb-2">
										Training Focus
									</h3>
									<p className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] text-gray-300">
										Comprehensive combat fitness program combining traditional
										martial arts with modern sports science.
									</p>
								</div>
							</div>
						</div>

						{/* Right Column - Content */}
						<div className="space-y-8 sm:space-y-10 md:space-y-12">
							{[
								{
									title: "A. Fundamental Stance & Movement",
									subtitle: "Building the Foundation",
									description:
										"Master the core principles of combat stance and movement patterns that form the foundation of effective fighting technique. Proper positioning and footwork create the basis for all advanced techniques.",
									items: [
										"Fighting Stance: Learning a balanced position for offense and defense.",
										"Movement in All Directions: Developing fluid mobility while maintaining guard.",
										"Advanced Movement Techniques: Incorporating feints and level changes to create unpredictability.",
									],
								},
								{
									title: "B. Striking Combinations & Defense",
									subtitle: "Advanced Striking Techniques",
									description:
										"Progress through increasingly complex striking combinations while developing defensive awareness. Focus on fluid transitions between offensive and defensive movements.",
									items: [
										"Combinations 1-4: Establishing fluid punch sequences (jab-cross-hook-cross).",
										"Head & Foot Movement: Defensive strategies like slips, counters, and pivots.",
										"Knee Strikes & Leg Kicks: Integrating powerful lower body strikes into combinations.",
									],
								},
								{
									title: "C. Conditioning & Fight Preparation",
									subtitle: "Combat Ready Training",
									description:
										"Build the stamina and explosiveness needed for real fight scenarios through structured conditioning programs and controlled sparring progression.",
									items: [
										"Endurance Rounds: High-rep, low-rest circuits mirroring real fights.",
										"Explosive Drills: Plyometrics combined with striking to develop power.",
										"Sparring Progression: Controlled sparring to integrate all learned techniques.",
									],
								},
							].map((section, index) => (
								<div
									key={index}
									className="bg-zinc-800/30 rounded-xl sm:rounded-2xl p-6 sm:p-8 
                     border border-zinc-700/50 hover:border-primary-light/50 
                     transition-all duration-300">
									<h3 className="text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] font-bold mb-2 sm:mb-3">
										<span
											className="bg-gradient-to-r from-primary-light to-primary-dark 
                             bg-clip-text text-white">
											{section.title}
										</span>
									</h3>
									<p
										className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                         text-primary-light/70 mb-4 sm:mb-6">
										{section.subtitle}
									</p>
									<p
										className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                         text-gray-300 mb-6 sm:mb-8">
										{section.description}
									</p>

									<ul className="space-y-4 sm:space-y-6">
										{section.items.map((item, idx) => (
											<li
												key={idx}
												className="flex items-start gap-3 sm:gap-4 group">
												<span
													className="flex-shrink-0 w-6 h-6 sm:w-8 sm:h-8 rounded-full 
                                 border-2 border-primary-light/50 flex items-center 
                                 justify-center mt-1 group-hover:bg-primary-light/20 
                                 transition-all duration-300">
													<span
														className="text-[0.75rem] sm:text-[0.875rem] font-semibold 
                                   text-primary-light">
														{idx + 1}
													</span>
												</span>
												<span
													className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                                 text-gray-300 group-hover:text-primary-light/90 
                                 transition-colors duration-300">
													{item}
												</span>
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>

			{/* Gamification and Performance Tracking */}
			<section className="py-16">
				<div className="container mx-auto px-6">
					{/* Main Content */}
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5">
							<h2 className="text-4xl font-bold bg-clip-text text-center text-lightShade bg-gradient-to-r from-primary to-primary-light">
								Gamification & Performance Tracking
							</h2>
						</div>
						<div className="grid lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-10">
							{/* Feature Cards */}
							<div className="space-y-4 sm:space-y-6">
								{[
									{
										title: "Progress Tracking Features",
										icon: faChartPie,
										items: [
											{
												name: "Achievement Badges & Level Progression",
												details:
													"Earn badges and advance through skill-based levels",
												benefits:
													"Master new techniques with structured progression paths",
											},
											{
												name: "Leaderboards & Challenges",
												details:
													"Compete in weekly challenges and rank on leaderboards",
												benefits: "Stay motivated through friendly competition",
											},
											{
												name: "Performance Metrics",
												details: "Track the percentage of modules completed",
												benefits:
													"Monitor progress and adjust training intensity",
											},
										],
									},
								].map((component, index) => (
									<div
										key={index}
										className="bg-zinc-800/30 rounded-xl sm:rounded-2xl p-6 sm:p-8 lg:p-10 
        border border-zinc-700/50 hover:border-primary-light/50 
        transition-all duration-300">
										<div className="flex items-center gap-3 sm:gap-4 mb-6 sm:mb-8">
											<div className="p-3 sm:p-4 bg-gradient-to-br from-primary-dark/20 to-primary-light/20 rounded-xl">
												<FontAwesomeIcon
													icon={component.icon}
													className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide"
												/>
											</div>
											<h4 className="text-lg sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
												{component.title}
											</h4>
										</div>

										<div className="space-y-4 sm:space-y-6">
											{component.items.map((item, idx) => (
												<div
													key={idx}
													className="bg-zinc-800/50 rounded-xl p-4 sm:p-6 hover:bg-zinc-700/30 
                    transition-all duration-300">
													<h5
														className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                        font-semibold text-primary-light mb-2 sm:mb-3">
														{item.name}
													</h5>
													<p
														className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                        text-gray-400 mb-2">
														{item.details}
													</p>
													<p
														className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                        text-gray-300">
														{item.benefits}
													</p>
												</div>
											))}
										</div>
									</div>
								))}
							</div>

							{/* Image Side */}
							<div className="relative">
								<div className="rounded-xl sm:rounded-2xl overflow-hidden">
									<img
										src="/static/mma/tp-9.png"
										alt="Performance Tracking"
										className="w-full h-[500px] sm:h-[600px] md:h-[700px] object-cover object-top"
									/>
									<div className="absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/50 to-transparent" />

									{/* Stats Grid */}
									<div
										className="absolute bottom-4 sm:bottom-6 md:bottom-8 
                       left-4 sm:left-6 md:left-8 
                       right-4 sm:right-6 md:right-8 
                       grid grid-cols-3 gap-3 sm:gap-4">
										{[
											{value: "87%", label: "Completion Rate"},
											{value: "92%", label: "User Engagement"},
											{value: "3.5x", label: "Progress Rate"},
										].map((stat, idx) => (
											<div
												key={idx}
												className="bg-zinc-900/80 backdrop-blur-sm rounded-lg sm:rounded-xl 
                         p-3 sm:p-4 md:p-5 border border-zinc-700/50 text-center">
												<div
													className="text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] 
                             font-bold text-primary-light">
													{stat.value}
												</div>
												<div
													className="text-[0.75rem] sm:text-[0.875rem] md:text-[1rem] 
                             text-gray-400">
													{stat.label}
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Future of Combat Fitness Section */}
			<section className="py-16 bg-zinc-800/30">
				<div className="container mx-auto px-6">
					{/* Main Content */}
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5">
							<h2 className="text-4xl font-bold bg-clip-text text-center text-lightShade bg-gradient-to-r from-primary to-primary-light">
								The Future of Combat Fitness at Adrenaline MMA
							</h2>
						</div>
					</div>

					<div className="grid lg:grid-cols-2 gap-8 sm:gap-12 lg:gap-16">
						{/* Content Side */}
						<div className="space-y-8 sm:space-y-10 md:space-y-12">
							{[
								{
									title: "A. Expansion into Digital Training",
									desc: "With the launch of the Adrenaline MMA Fitness Web Application, the gym is scaling its combat fitness science into high impact combat fitness training.",
									features: [
										{
											name: "AI-Powered Training Plans",
											desc: "Personalized training plans that adapt based on individual feedback and progress.",
											icon: faBrain,
										},
										{
											name: "Virtual Coaching System",
											desc: "Real-time form corrections and video feedback from professional coaches.",
											icon: faVideo,
										},
										{
											name: "Structured Badge System",
											desc: "Maintain progression beyond the gym with achievement-based milestones.",
											icon: faMedal,
										},
									],
								},
								{
									title: "B. Community & Engagement",
									desc: "Stay connected and motivated with our integrated community features and social engagement tools.",
									features: [
										{
											name: "Social Integration",
											desc: "WhatsApp & Social Media integration for peer support and coaching feedback.",
											icon: faUsers,
										},
										{
											name: "Video Challenges",
											desc: "Participate in video-based fitness challenges to reinforce skills remotely.",
											icon: faVideo,
										},
										{
											name: "Progress Sharing",
											desc: "Share achievements and milestones with the Adrenaline MMA community.",
											icon: faShare,
										},
									],
								},
								{
									title: "C. Injury Prevention & Recovery Protocols",
									desc: "Adrenaline MMA follows scientific best practices for injury prevention and recovery to ensure long-term athlete health and performance.",
									features: [
										{
											name: "Structured Warm-Ups",
											desc: "Reducing injury risk by 30% with dynamic stretching routines and mobility work.",
											icon: faShieldHalved,
										},
										{
											name: "Post-Training Recovery",
											desc: "Cooling down with static stretches and mobility work to decrease lactic acid buildup.",
											icon: faChartLine,
										},
										{
											name: "Technique Supervision",
											desc: "Ensuring proper striking mechanics and form to reduce shoulder and joint injuries.",
											icon: faRankingStar,
										},
									],
								},
							].map((section, idx) => (
								<div
									key={idx}
									className="bg-zinc-800/30 rounded-xl sm:rounded-2xl p-6 sm:p-8 lg:p-10 
                     border border-zinc-700/50 hover:border-primary-light/50 
                     transition-all duration-300">
									<h3
										className="text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] 
                         font-bold mb-3 sm:mb-4 
                         bg-gradient-to-r from-primary-light to-primary-dark 
                         bg-clip-text text-white">
										{section.title}
									</h3>
									<p
										className="text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] 
                       text-gray-300 mb-6 sm:mb-8 leading-relaxed">
										{section.desc}
									</p>

									<div className="grid gap-4 sm:gap-6">
										{section.features.map((feature, featureIdx) => (
											<div
												key={featureIdx}
												className="flex items-start gap-4 sm:gap-6 group">
												<div
													className="p-3 sm:p-4 bg-gradient-to-br from-primary-dark/20 
                               to-primary-light/20 rounded-lg 
                               group-hover:bg-primary-light/20 transition-all duration-300">
													<FontAwesomeIcon
														icon={feature.icon}
														className="text-[1.25rem] sm:text-[1.5rem] md:text-[1.75rem] 
                               text-primary-light"
													/>
												</div>
												<div>
													<h4
														className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                                 font-semibold text-primary-light mb-2">
														{feature.name}
													</h4>
													<p
														className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                                text-gray-400 leading-relaxed">
														{feature.desc}
													</p>
												</div>
											</div>
										))}
									</div>
								</div>
							))}
						</div>

						{/* Image Side */}
						<div className="relative">
							<div className="sticky top-24 rounded-xl sm:rounded-2xl overflow-hidden">
								<img
									src="/static/mma/tp-10.png"
									alt="Future of Combat Fitness"
									className="w-full h-[600px] sm:h-[700px] md:h-[800px] lg:h-[900px] object-cover"
								/>
								<div className="absolute inset-0 bg-gradient-to-t from-zinc-900 via-zinc-900/50 to-transparent" />

								{/* Info Cards */}
								<div
									className="absolute bottom-4 sm:bottom-6 md:bottom-8 
                       left-4 sm:left-6 md:left-8 
                       right-4 sm:right-6 md:right-8 space-y-3 sm:space-y-4">
									<div
										className="bg-zinc-900/80 backdrop-blur-sm rounded-xl 
                         p-4 sm:p-6 lg:p-8 border border-zinc-700/50">
										<h3
											className="text-[1.125rem] sm:text-[1.25rem] md:text-[1.375rem] 
                           font-semibold text-primary-light mb-2 sm:mb-3">
											Digital Innovation
										</h3>
										<p
											className="text-[0.875rem] sm:text-[1rem] md:text-[1.125rem] 
                         text-gray-300 leading-relaxed">
											Leveraging technology to enhance and scale the combat
											fitness experience.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Combat Science Summary */}
			<section className="py-16 sm:py-24 md:py-32 relative overflow-hidden min-h-screen flex items-center">
				{/* Background Image with Advanced Overlay */}
				<div className="absolute inset-0 z-0">
					<img
						src="/static/mma/tp-17.jpg"
						alt="Combat Science Background"
						className="w-full h-full object-cover object-[center_top] transition-transform duration-500 hover:scale-105"
					/>
					<div className="absolute inset-0 bg-gradient-to-br from-black/95 via-zinc-900/95 to-zinc-900/90 backdrop-blur-xs" />

					{/* Animated Gradient Overlay */}
					<div className="absolute inset-0 bg-gradient-to-r from-primary-dark/10 to-transparent animate-gradient-x" />

					{/* Mesh Gradient Effect */}
					<div className="absolute inset-0 opacity-40 mix-blend-overlay bg-[radial-gradient(circle_at_50%_50%,rgba(193,76,0,0.1),transparent_70%)]" />
				</div>

				{/* Content Container */}
				<div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
					<div className="max-w-4xl mx-auto">
						{/* Title Section */}
						<div className="text-center mb-8 sm:mb-12 md:mb-16 relative">
							<h2
								className="text-[2rem] sm:text-[2.5rem] md:text-[3rem] lg:text-[3.5rem] xl:text-[4rem] 
                     font-bold animate-fade-in 
                     bg-gradient-to-r from-primary-light via-primary hover:via-primary-dark to-primary-light 
                     bg-clip-text text-white hover:animate-power-pulse 
                     transition-all duration-400 cursor-default
                     animate-gradient-flow bg-[length:200%_auto]">
								Combat Science
							</h2>

							{/* Decorative Line */}
							<div
								className="w-16 sm:w-20 md:w-24 h-0.5 sm:h-1 mx-auto mt-4 sm:mt-6 md:mt-8 
                     bg-gradient-to-r from-primary-light to-primary-dark 
                     animate-expandWidth rounded-full"
							/>
						</div>

						{/* Content Cards */}
						<div className="grid gap-4 sm:gap-6 md:gap-8">
							{[
								{
									text: "Adrenaline MMA Gym has successfully applied the science of combat fitness by integrating calisthenics, core training, high-intensity functional workouts, and structured progression through the Blue Level badge system.",
									delay: "0",
								},
								{
									text: "By leveraging scientific training principles, biometric tracking, gamification, and injury prevention protocols, the gym ensures optimal fighter performance and safety.",
									delay: "200",
								},
								{
									text: "As it expands into digital training, Adrenaline MMA continues to innovate, making elite combat fitness accessible both in the gym and online.",
									delay: "400",
								},
							].map((item, index) => (
								<div
									key={index}
									className="bg-zinc-900/30 backdrop-blur-sm rounded-xl sm:rounded-2xl 
                     p-4 sm:p-6 md:p-8 border border-zinc-700/50
                     hover:border-primary-light/50 transition-all duration-400 animate-slideUp
                     hover:bg-gradient-to-r hover:from-zinc-900/40 hover:to-primary-dark/5
                     hover:shadow-lg hover:shadow-primary-dark/10 group"
									style={{animationDelay: `${item.delay}ms`}}>
									<p
										className="text-[1rem] sm:text-[1.125rem] md:text-[1.25rem] lg:text-[1.375rem]
                       text-gray-300 group-hover:text-primary-light/90 
                       transition-colors duration-250 ease-out-expo relative leading-relaxed">
										{/* Decorative Element */}
										<span
											className="absolute -left-2 sm:-left-3 md:-left-4 top-0 w-0.5 sm:w-1 h-full 
                           bg-primary-light/20 group-hover:h-full group-hover:bg-primary-light 
                           transition-all duration-400 rounded-full"
										/>
										{item.text}
									</p>
								</div>
							))}
						</div>

						{/* Bottom Decorative Element */}
						<div className="mt-8 sm:mt-12 md:mt-16 flex justify-center">
							<div className="w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 relative animate-spin-slow">
								<div
									className="absolute inset-0 rounded-full border-2 border-primary-light/20 
                       animate-pulse-glow"
								/>
								<div
									className="absolute inset-2 rounded-full border-2 border-primary-light/40 
                       animate-reverse-spin"
								/>
								<div
									className="absolute inset-4 rounded-full border-2 border-primary-light/60 
                       animate-badge-spin"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<footer className="bg-black py-12">
				<div className="container mx-auto px-6">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="text-gray-400 mb-6 md:mb-0">
							&copy; 2025 Adrenaline MMA. All rights reserved.
						</div>
						<div className="flex space-x-8">
							<Link
								to="https://www.facebook.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faFacebook} size="lg" />
							</Link>
							{/* <Link
								to="https://www.twitter.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faTwitter} size="lg" />
							</Link> */}
							<Link
								to="https://www.instagram.com/adrenalinemmahome/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faInstagram} size="lg" />
							</Link>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default ScienceOfCombatFitness;
