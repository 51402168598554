import React, {useEffect} from "react";
import Scroll from "react-scroll";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faStopwatch,
	faCalendarAlt,
	faRepeat,
	faSignInAlt,
	faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
	faFacebook,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";

function Adrenaline12WeekCombatFitnessProgram() {
	useEffect(() => {
		Scroll.animateScroll.scrollToTop();
	}, []);

	const programWeeks = [
		{
			title: "Foundations & Footwork",
			weeks: "1-3",
			img: "/static/mma/img1.png",
			sections: [
				{
					name: "Warm-Up",
					duration: "10 minutes",
					activities: "Jump rope, bounce drills, and dynamic stretching",
				},
				{
					name: "Technical Drills",
					duration: "20 minutes",
					activities: "Focus on fighting stance, movement, and shadow boxing",
				},
				{
					name: "Strength & Conditioning",
					duration: "20 minutes",
					activities: "Squats, push-ups, core work, and burpees",
				},
			],
		},
		{
			title: "Striking Combinations & Defense",
			weeks: "4-6",
			img: "/static/mma/tp-1.jpg",
			sections: [
				{
					name: "Warm-Up",
					duration: "10 minutes",
					activities: "Jump rope and shadow boxing with head movement",
				},
				{
					name: "Technical Drills",
					duration: "20 minutes",
					activities: "Practice punching combinations and defense drills",
				},
				{
					name: "Strength & Conditioning",
					duration: "20 minutes",
					activities: "Jump squats, pull-ups, core exercises, and high knees",
				},
			],
		},
		{
			title: "Advanced Striking & Kicks",
			weeks: "7-9",
			img: "/static/mma/tp-3.png",
			sections: [
				{
					name: "Warm-Up",
					duration: "10 minutes",
					activities:
						"Jump rope, shadow boxing with knees, and mobility exercises",
				},
				{
					name: "Technical Drills",
					duration: "20 minutes",
					activities:
						"Focus on knees, kicks, and defensive movement after strikes",
				},
				{
					name: "Strength & Conditioning",
					duration: "20 minutes",
					activities: "Box jumps, push-ups, core work, and sprint intervals",
				},
			],
		},
		{
			title: "Full Combat Flow & High-Intensity",
			weeks: "10-12",
			img: "/static/mma/tp-2.jpg",
			sections: [
				{
					name: "Warm-Up",
					duration: "10 minutes",
					activities: "Jump rope and full-flow shadow boxing",
				},
				{
					name: "Technical Drills",
					duration: "20 minutes",
					activities: "Combine offense and defense in flowing sequences",
				},
				{
					name: "Strength & Conditioning",
					duration: "20 minutes",
					activities: "Explosive exercises and max effort burnouts",
				},
			],
		},
	];

	return (
		<div className="min-h-screen bg-backgroundDark text-textLight">
			{/* Navigation Bar */}
			<nav className="fixed top-0 w-full bg-black/80 backdrop-blur-md z-50">
				<div className="container mx-auto px-6 py-4">
					<div className="flex justify-between items-center">
						<div className="flex items-center space-x-16">
							<img src="logo.svg" alt="Logo" className="h-12 w-auto" />
						</div>
						<div className="flex items-center space-x-6">
							<Link
								to="/login"
								className="text-gray-300 hover:text-white transition-colors duration-300 flex items-center space-x-2 group">
								<FontAwesomeIcon
									icon={faSignInAlt}
									className="group-hover:rotate-12 transition-transform duration-300"
								/>
								<span>Login</span>
							</Link>
							<Link
								to="/register"
								className="bg-primary hover:bg-primary/30 px-6 py-2 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center space-x-2 shadow-lg shadow-primary/20">
								<FontAwesomeIcon
									icon={faUserPlus}
									className="group-hover:scale-110 transition-transform duration-300"
								/>
								<span>Get Started</span>
							</Link>
						</div>
					</div>
				</div>
			</nav>
			{/* Hero Section */}
			<section className="relative pt-28 pb-16 bg-gradient-to-b from-primary-dark to-darkShade">
				<div className="container mx-auto px-6 relative z-10">
					<div className="mx-auto max-w-5xl">
						<div className="space-y-8 text-center">
							<h1
								className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 
								font-bold tracking-tight leading-[1.1] 
								bg-gradient-to-r from-primary-light to-primary-dark 
								bg-clip-text text-white animate-fade-in">
								12-Week Combat Fitness Program
							</h1>
							<p className="text-xl text-gray-300 mb-8 font-secondary">
								Elevate your fitness level with our intensive 12 week Combat
								Fitness program based on the Adrenaline Blue Best MMA system - 3
								days a week for 45-60 minutes per session.
							</p>
						</div>
					</div>
				</div>
			</section>

			{/* Program Overview */}
			<section className="py-16">
				<div className="container mx-auto px-6">
					<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
						<div className="bg-zinc-800/50 p-6 rounded-xl backdrop-blur-sm">
							<FontAwesomeIcon
								icon={faStopwatch}
								className="text-primary-light text-2xl mb-4"
							/>
							<h3 className="text-xl font-bold mb-2">Duration</h3>
							<p className="text-gray-400">45-60 minutes per session</p>
						</div>
						<div className="bg-zinc-800/50 p-6 rounded-xl backdrop-blur-sm">
							<FontAwesomeIcon
								icon={faCalendarAlt}
								className="text-primary-light text-2xl mb-4"
							/>
							<h3 className="text-xl font-bold mb-2">Frequency</h3>
							<p className="text-gray-400">3 days per week</p>
						</div>
						<div className="bg-zinc-800/50 p-6 rounded-xl backdrop-blur-sm">
							<FontAwesomeIcon
								icon={faRepeat}
								className="text-primary-light text-2xl mb-4"
							/>
							<h3 className="text-xl font-bold mb-2">Rest Period</h3>
							<p className="text-gray-400">1-2 minutes between circuits</p>
						</div>
					</div>

					{/* Program Weeks */}
					<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
						{programWeeks.map((phase, index) => (
							<div key={index} className="mb-20 md:mb-32 last:mb-0">
								{/* Phase Header */}
								<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
									<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
									<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
									<h2 className="text-4xl font-bold bg-clip-text text-lightShade bg-gradient-to-r from-primary to-primary-light">
										Weeks {phase.weeks}
									</h2>
									<h3 className="text-lg italic sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
										{phase.title}
									</h3>
								</div>

								{/* Timeline with Image Split */}
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8 lg:gap-12">
									{/* Left: Timeline Content */}
									<div className="relative order-2 lg:order-1">
										{/* Main vertical line */}
										<div
											className="absolute left-[28px] sm:left-[35px] top-0 bottom-0 w-[2px] md:w-[3px] 
                        bg-gradient-to-b from-zinc-800 via-primary-DEFAULT/20 to-zinc-800"
										/>

										{phase.sections.map((section, sectionIndex) => (
											<div
												key={sectionIndex}
												className="relative pl-[70px] sm:pl-[90px] pb-12 sm:pb-16 last:pb-0 group">
												{/* Number indicator */}
												<div className="absolute left-0 top-0">
													<div
														className="w-[56px] h-[56px] sm:w-[72px] sm:h-[72px] 
                              bg-zinc-900/80 backdrop-blur-sm
                              border-2 border-zinc-800 
                              group-hover:border-primary
                              transition-colors duration-300
                              flex items-center justify-center
                              shadow-lg shadow-black/20">
														<span
															className="text-2xl sm:text-3xl font-bold 
                                 bg-gradient-to-br from-primary-light to-primary-DEFAULT 
                                 bg-clip-text text-transparent">
															{sectionIndex + 1}
														</span>
													</div>
												</div>

												{/* Horizontal connector */}
												<div
													className="absolute left-[56px] sm:left-[72px] top-[27px] sm:top-[35px] 
                            w-[14px] sm:w-[18px] h-[2px] sm:h-[3px] bg-zinc-800
                            group-hover:bg-primary-DEFAULT/50 
                            transition-colors duration-300"
												/>

												{/* Content Card */}
												<div
													className="bg-zinc-900/40 backdrop-blur-md 
                            border border-zinc-800/50 
                            group-hover:border-zinc-700
                            transition-colors duration-300
                            p-4 sm:p-6 md:p-8 rounded-lg
                            shadow-xl shadow-black/10">
													{/* Header */}
													<div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 mb-4">
														<h4 className="text-lg sm:text-xl md:text-2xl font-bold text-white">
															{section.name}
														</h4>
														<div
															className="flex items-center gap-2 px-3 py-1 sm:px-4 sm:py-1.5 
                                bg-zinc-800/50 backdrop-blur-sm
                                border border-zinc-700/50 rounded-full
                                w-fit">
															<FontAwesomeIcon
																icon={faStopwatch}
																className="text-primary-light text-xs sm:text-sm"
															/>
															<span className="text-xs sm:text-sm text-gray-300 font-medium">
																{section.duration}
															</span>
														</div>
													</div>

													{/* Content */}
													<p className="text-gray-300 text-sm sm:text-base md:text-lg leading-relaxed">
														{section.activities}
													</p>
												</div>
											</div>
										))}
									</div>

									{/* Right: Image Section */}
									<div className="order-1 lg:order-2">
										<div className="lg:sticky lg:top-8 w-full">
											<div className="relative aspect-[4/5] rounded-lg sm:rounded-xl overflow-hidden">
												<img
													src={phase?.img}
													alt={`Training phase ${index + 1}: ${phase.title}`}
													className="object-cover w-full h-full brightness-90"
													loading={index === 0 ? "eager" : "lazy"}
												/>
												{/* Image Overlay */}
												<div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent" />

												{/* Phase indicator */}
												<div className="absolute bottom-4 sm:bottom-6 left-4 sm:left-6 right-4 sm:right-6">
													<div className="bg-black/60 backdrop-blur-sm px-3 py-2 sm:px-4 sm:py-3 rounded-lg">
														<h4 className="text-base sm:text-lg font-medium text-primary-light mb-0.5 sm:mb-1">
															Phase {index + 1}
														</h4>
														<p className="text-xs sm:text-sm text-gray-300">
															{phase.title}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>

			{/* Final Notes */}
			<section className="py-16 bg-gradient-to-b from-zinc-900 to-backgroundDark">
				<div className="container mx-auto px-6">
					<h2 className="text-3xl font-bold mb-12 text-center font-primary">
						Final Notes
					</h2>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
						<div className="bg-zinc-800/50 p-6 rounded-xl backdrop-blur-sm">
							<h3 className="text-xl font-bold mb-4 text-primary-light">
								Progressive Load
							</h3>
							<p className="text-gray-400">
								Increase intensity weekly by adding reps or reducing rest.
							</p>
						</div>
						<div className="bg-zinc-800/50 p-6 rounded-xl backdrop-blur-sm">
							<h3 className="text-xl font-bold mb-4 text-primary-light">
								Equipment
							</h3>
							<p className="text-gray-400">
								Alternate between shadow boxing and heavy bag work if available.
							</p>
						</div>
						<div className="bg-zinc-800/50 p-6 rounded-xl backdrop-blur-sm">
							<h3 className="text-xl font-bold mb-4 text-primary-light">
								Partner Work
							</h3>
							<p className="text-gray-400">
								Incorporate partner drills for reaction training when possible.
							</p>
						</div>
					</div>
				</div>
			</section>

			{/* Footer */}
			<footer className="bg-black py-12">
				<div className="container mx-auto px-6">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="text-gray-400 mb-6 md:mb-0">
							&copy; 2025 Adrenaline MMA. All rights reserved.
						</div>
						<div className="flex space-x-8">
							<Link
								to="https://www.facebook.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faFacebook} size="lg" />
							</Link>
							{/* <Link
								to="https://www.twitter.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faTwitter} size="lg" />
							</Link> */}
							<Link
								to="https://www.instagram.com/adrenalinemmahome/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faInstagram} size="lg" />
							</Link>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default Adrenaline12WeekCombatFitnessProgram;
