import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { currentUserState } from "../../../AppState";
import FormErrorBox from "../../Reusable/FormErrorBox";

function TwoFactorAuthenticationWizardStep1() {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [errors, setErrors] = useState({});
  const [forceURL, setForceURL] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-zinc-900 to-backgroundDark py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <div className="bg-zinc-800/30 backdrop-blur-sm rounded-2xl p-8 shadow-xl">
          {/* Progress Bar */}
          <div className="bg-zinc-900/50 rounded-xl p-6 mb-8">
            <p className="text-gray-300 text-lg mb-2">Step 1 of 3</p>
            <div className="w-full bg-gray-700 rounded-full h-2">
              <div className="bg-primary w-1/3 h-2 rounded-full animate-pulse-glow"></div>
            </div>
          </div>

          {/* Header */}
          <h1 className="text-3xl font-bold text-center text-lightShade mb-8 font-primary">
            Setup Two-Factor Authentication
          </h1>
          
          <FormErrorBox errors={errors} />

          {/* Description */}
          <p className="text-gray-400 mb-6">
            To ensure your account stays secure, you need to sign in using 
            <span className="italic"> two-factor Authentication (2FA)</span>. 
            The following wizard will help you get setup with 2FA.
          </p>
          
          <p className="text-gray-400 mb-8">
            To begin, please download any of the following applications for your mobile device.
          </p>

          {/* Cards Container */}
          <div className="space-y-6">
            {/* Apple 2FA Card */}
            <div className="group bg-zinc-900/50 rounded-xl p-6 hover:bg-zinc-800/50 transition-all duration-300">
              <h3 className="text-xl font-bold text-primary-light mb-4 underline">
                Apple 2FA
              </h3>
              <p className="text-gray-400">
                All iOS and Mac devices with a <span className="font-bold">Safari Web Browser</span> come 
                with built-in 2FA verification services. Sign in with your <span className="italic">Apple ID</span> in 
                Safari and you can take advantage of this service.
              </p>
            </div>

            {/* Google Authenticator Card */}
            <div className="group bg-zinc-900/50 rounded-xl p-6 hover:bg-zinc-800/50 transition-all duration-300">
              <h3 className="text-xl font-bold text-primary-light mb-4 underline">
                Google Authenticator
              </h3>
              <p className="text-gray-400 mb-4">
                This 2FA app is created by <span className="font-bold">Google, Inc.</span>
              </p>
              <div className="space-y-2">
                <div>
                  <span className="text-gray-300 font-bold">Download for iOS:</span>
                  <Link 
                    to="https://apps.apple.com/ca/app/google-authenticator/id388497605"
                    target="_blank"
                    rel="noreferrer"
                    className="ml-2 text-primary-light hover:text-primary-hover transition-colors inline-flex items-center"
                  >
                    Visit App Store
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2 text-sm" />
                  </Link>
                </div>
                <div>
                  <span className="text-gray-300 font-bold">Download for Android:</span>
                  <Link 
                    to="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                    target="_blank"
                    rel="noreferrer"
                    className="ml-2 text-primary-light hover:text-primary-hover transition-colors inline-flex items-center"
                  >
                    Visit Google Play
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2 text-sm" />
                  </Link>
                </div>
              </div>
            </div>

            {/* Authenticator Chrome Extension Card */}
            <div className="group bg-zinc-900/50 rounded-xl p-6 hover:bg-zinc-800/50 transition-all duration-300">
              <h3 className="text-xl font-bold text-primary-light mb-4 underline">
                Authenticator
              </h3>
              <p className="text-gray-400 mb-4">
                This 2FA app is created by <span className="font-bold">authenticator.cc</span>
              </p>
              <div>
                <span className="text-gray-300 font-bold">Download for Chrome:</span>
                <Link 
                  to="https://chromewebstore.google.com/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-2 text-primary-light hover:text-primary-hover transition-colors inline-flex items-center"
                >
                  Visit Chrome web store
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2 text-sm" />
                </Link>
              </div>
            </div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between mt-8">
            <Link
              to="/login"
              className="inline-flex items-center px-6 py-3 rounded-xl bg-zinc-700 text-gray-200 hover:bg-zinc-600 transition-colors"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Cancel
            </Link>
            <Link
              to="/login/2fa/step-2"
              className="inline-flex items-center px-6 py-3 rounded-xl bg-primary-DEFAULT text-lightShade hover:bg-primary-hover transition-colors animate-pulse-glow"
            >
              Next
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Link>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center mt-8 text-gray-400">
          <p>© 2024 The Adrenaline MMA Training & Fitness Center Team</p>
        </div>
      </div>
    </div>
  );
}

export default TwoFactorAuthenticationWizardStep1;