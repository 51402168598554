import React, { useState, useEffect } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import Scroll from "react-scroll";

import FormErrorBox from "../../Reusable/FormErrorBox";
import { postValidateOTP } from "../../../API/gateway";
import { currentOTPResponseState, currentUserState } from "../../../AppState";
import {
  ROOT_ROLE_ID,
  ADMIN_ROLE_ID,
  TRAINER_ROLE_ID,
  MEMBER_ROLE_ID,
} from "../../../Constants/App";

function TwoFactorAuthenticationValidateOnLogin() {
  const [searchParams] = useSearchParams();
  const paramToken = searchParams.get("token");
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [otpResponse] = useRecoilState(currentOTPResponseState);
  const [errors, setErrors] = useState({});
  const [forceURL, setForceURL] = useState("");
  const [token, setToken] = useState("");
  const [submittedParamToken, setSubmittedParamToken] = useState(false);

  function onVerifyOPTSuccess(response) {
    if (response?.user) {
      setCurrentUser(response.user);
      switch (response.user.role) {
        case ROOT_ROLE_ID: 
          setForceURL("/root/tenants"); 
          break;
        case ADMIN_ROLE_ID: 
          setForceURL("/admin/dashboard"); 
          break;
        case TRAINER_ROLE_ID: 
          setForceURL("/admin/dashboard"); 
          break;
        case MEMBER_ROLE_ID: 
          setForceURL(response?.user?.onboardingCompleted ? "/dashboard" : "/onboarding");
          break;
        default: 
          setForceURL("/501"); 
          break;
      }
    }
  }

  function onVerifyOPTError(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onButtonClick() {
    const cleanedToken = token.replace(/\s/g, "");
    postValidateOTP(
      { token: cleanedToken },
      onVerifyOPTSuccess,
      onVerifyOPTError,
      () => console.log("onVerifyOPTDone: Starting...")
    );
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      window.scrollTo(0, 0);
      if (!submittedParamToken && paramToken) {
        postValidateOTP(
          { token: paramToken },
          onVerifyOPTSuccess,
          onVerifyOPTError,
          () => console.log("onVerifyOPTDone: Starting...")
        );
        setSubmittedParamToken(true);
        setToken(paramToken);
      }
    }
    return () => (mounted = false);
  }, [paramToken, submittedParamToken]);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-zinc-900 to-backgroundDark py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <div className="bg-zinc-800/30 backdrop-blur-sm rounded-2xl p-8 shadow-xl animate-fade-in">
          {/* Logo */}
          <div className="flex justify-center mb-8">
            <Link to="/" className="transform hover:scale-105 transition-transform duration-300">
              <img
                src="/static/logo.png"
                alt="Adrenaline MMA Logo"
                className="w-64 h-auto"
              />
            </Link>
          </div>

          {/* Header */}
          <h1 className="text-3xl font-bold text-center text-lightShade mb-8 font-primary">
            Two-Factor Authentication
          </h1>
          
          <FormErrorBox errors={errors} />

          {/* Description */}
          <p className="text-gray-400 mb-8">
            Open the two-step verification app on your mobile device, get your token 
            and input here to finish your login.
          </p>

          {/* Token Input */}
          <div className="space-y-4 mb-8">
            <label className="block text-gray-300 text-sm font-medium">
              Enter your Token
              <span className="text-primary-light">*</span>
            </label>
            <input
              type="text"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              className="w-full max-w-[380px] px-4 py-3 bg-zinc-900/50 border border-gray-700 
                         rounded-xl text-gray-200 placeholder-gray-500 
                         focus:ring-2 focus:ring-primary-light focus:border-transparent 
                         transition-all duration-300"
              placeholder="See your authenticator app"
            />
            {errors?.token && (
              <p className="text-red-500 text-sm mt-1">{errors.token}</p>
            )}
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between mt-8">
            <Link
              to="/login"
              className="inline-flex items-center px-6 py-3 rounded-xl 
                       bg-zinc-700 text-gray-200 hover:bg-zinc-600 
                       transition-colors"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back to Login
            </Link>
            <button
              onClick={onButtonClick}
              className="inline-flex items-center px-6 py-3 rounded-xl 
                       bg-primary-DEFAULT text-lightShade hover:bg-primary-hover 
                       transition-colors animate-pulse-glow"
            >
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              Submit and Login
            </button>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center mt-8 text-gray-400">
          <p>© 2024 The Adrenaline MMA Training & Fitness Center Team</p>
        </div>
      </div>
    </div>
  );
}

export default TwoFactorAuthenticationValidateOnLogin;