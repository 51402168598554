import React from "react";
import WorkoutDraggableItem from "./WorkoutDraggable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faDumbbell,
	faClock,
	faGripVertical,
	faFire,
	faPlus,
	faChevronRight,
	faMedal,
} from "@fortawesome/free-solid-svg-icons";

function WorkoutDisplay({workouts, onAdd}) {
	return (
		<div className="max-h-[410px] overflow-y-auto">
			{/* Exercise List */}
			{workouts && workouts.length > 0 && workouts !== null ? (
				<div className="space-y-3">
					{workouts.map((workout, index) => (
						<WorkoutRow
							key={workout?.id}
							workout={workout}
							index={index}
							onAdd={onAdd}
						/>
					))}
				</div>
			) : (
				<EmptyState />
			)}
		</div>
	);
}

const WorkoutRow = ({workout, index, onAdd}) => (
	<WorkoutDraggableItem
		id={workout?.id}
		onAdd={onAdd}
		workout={workout}
		component="div"
		className="transform transition-all duration-300"
		content={
			<div
				className="group relative bg-white rounded-xl border border-gray-100 hover:border-primary/30 
                    shadow-sm hover:shadow-md transition-all duration-300">
				<div className="flex items-center p-4">
					{/* Drag Handle */}
					<div className="px-2 cursor-grab">
						<FontAwesomeIcon
							icon={faGripVertical}
							className="text-gray-300 group-hover:text-primary transition-colors duration-300"
						/>
					</div>

					{/* Number Badge */}
					<div
						className="w-12 h-12 rounded-xl bg-gray-50 flex items-center justify-center mr-4
                       group-hover:bg-primary/5 transition-colors duration-300">
						<span className="font-semibold text-gray-500 group-hover:text-primary">
							{String(index + 1).padStart(2, "0")}
						</span>
					</div>

					{/* Thumbnail */}
					<div className="relative w-20 h-20 rounded-lg overflow-hidden mr-6 flex-shrink-0">
						{workout?.thumbnailObjectUrl ? (
							<>
								<img
									src={workout.thumbnailObjectUrl}
									alt={workout.name}
									className="w-full h-full object-cover transform group-hover:scale-110 
                           transition-transform duration-700"
								/>
								<div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent" />
							</>
						) : (
							<div className="w-full h-full bg-gray-100 flex items-center justify-center">
								<FontAwesomeIcon
									icon={faDumbbell}
									className="text-gray-300 text-2xl"
								/>
							</div>
						)}
					</div>

					{/* Content */}
					<div className="flex-grow flex items-center justify-between mr-4">
						<div>
							<h3
								className="text-lg font-semibold text-gray-900 group-hover:text-primary 
                           transition-colors duration-300">
								{workout?.name}
							</h3>

							<div className="flex items-center gap-4 mt-2">
								{workout?.duration && (
									<div className="flex items-center gap-2 text-sm text-gray-500">
										<FontAwesomeIcon
											icon={faClock}
											className="text-primary/70"
										/>
										{workout.duration}
									</div>
								)}

								{workout?.difficulty && (
									<div className="flex items-center gap-2 text-sm text-gray-500">
										<FontAwesomeIcon icon={faFire} className="text-amber-500" />
										{workout.difficulty}
									</div>
								)}

								{workout?.category && (
									<div className="flex items-center gap-2 text-sm">
										<FontAwesomeIcon
											icon={faMedal}
											className="text-primary/70"
										/>
										<span className="px-2.5 py-1 bg-primary/10 text-primary rounded-full text-xs font-medium">
											{workout.category}
										</span>
									</div>
								)}
							</div>
						</div>
					</div>

					{/* Add Button */}
					<button
						onClick={() => onAdd({id: workout.id})}
						className="flex items-center gap-2 px-4 py-2 bg-gray-50 text-gray-600 rounded-lg
                     hover:bg-primary hover:text-white transition-all duration-300 
                     group-hover:shadow-sm">
						<FontAwesomeIcon icon={faPlus} className="text-sm" />
						<span className="font-medium">Add</span>
						<FontAwesomeIcon
							icon={faChevronRight}
							className="text-sm transform group-hover:translate-x-1 transition-transform duration-300"
						/>
					</button>
				</div>
			</div>
		}
	/>
);

const EmptyState = () => (
	<div className="flex flex-col items-center justify-center py-12">
		<div className="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mb-4">
			<FontAwesomeIcon icon={faDumbbell} className="text-primary text-2xl" />
		</div>
		<h3 className="text-lg font-semibold text-gray-900">No Exercises Found</h3>
		<p className="text-gray-500 text-center mt-2">
			Try adjusting your filters or search criteria
		</p>
	</div>
);

export default WorkoutDisplay;
