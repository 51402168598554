import React, {useState, useEffect} from "react";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTags,
	faPlus,
	faPencil,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";

import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {getMemberDetailAPI, putMemberUpdateAPI} from "../../../API/member";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";

// TagCard Component with edit/delete functionality
function TagCard({tag, onEdit, onDelete}) {
	return (
		<div className="bg-darkShade/7 rounded-2xl shadow-sm border border-gray-100 p-3">
			<div className="flex items-start gap-3 mb-4">
				<div className="w-10 h-10 rounded-xl bg-blue-100 flex items-center justify-center flex-shrink-0">
					<FontAwesomeIcon icon={faTags} className="w-5 h-5 text-blue-600" />
				</div>

				<div className="flex-1">
					<h3 className="text-base font-semibold text-gray-900 mb-1">
						{tag.text}
					</h3>
					{tag.description && (
						<p className="text-sm text-gray-500 line-clamp-2">
							{tag.description}
						</p>
					)}
				</div>
			</div>

			<div className="flex gap-2 justify-end mt-2">
				<button
					onClick={onEdit}
					className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 bg-gray-50 rounded-full hover:bg-gray-100">
					<FontAwesomeIcon icon={faPencil} className="w-3.5 h-3.5" />
					<span>Edit</span>
				</button>
				<button
					onClick={onDelete}
					className="flex items-center gap-2 px-3 py-1.5 text-sm text-red-600 bg-white rounded-full hover:bg-red-50 border border-red-100">
					<FontAwesomeIcon icon={faTrash} className="w-3.5 h-3.5" />
					<span>Delete</span>
				</button>
			</div>
		</div>
	);
}

function AdminMemberDetailForTags() {
	const {id} = useParams();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [member, setMember] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isFetching, setIsFetching] = useState(false);
	const [selectedTag, setSelectedTag] = useState(null);
	const [errors, setErrors] = useState({});
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const resetModals = () => {
		setShowAddModal(false);
		setShowEditModal(false);
		setShowDeleteModal(false);
		setSelectedTag(null);
	};

	// API Callbacks
	function onMemberDetailSuccess(response) {
		setMember(response);
		console.log(response);
		setErrors({});
		setIsLoading(false);
	}

	function onMemberDetailError(apiErr) {
		setErrors(apiErr);
		console.error("Member Detail API Error:", apiErr);
	}

	function onMemberDetailDone() {
		setIsLoading(false);
	}

	const handleSubmit = (formData) => {
		if (!formData) {
			resetModals();
			return;
		}
	
		// Create a copy of current user with proper initialization of tags
		const modifiedMember = {
			...member,
			address_line_1: member.addressLine1,
			tags: member.tags || []  // Initialize as empty array if null
		};
	
		if (selectedTag) {
			// Edit existing tag
			modifiedMember.tags = modifiedMember.tags.map((tag) =>
				tag.id === selectedTag.id
					? {
						...tag,
						text: formData.text,
						description: formData.description,
					}
					: tag
			);
		} else {
			// Add new tag
			if (!Array.isArray(modifiedMember.tags)) {
				modifiedMember.tags = [];  // Ensure tags is an array
			}
			modifiedMember.tags.push({
				text: formData.text,
				description: formData.description,
			});
		}
	
		setIsFetching(true);
		putMemberUpdateAPI(
			modifiedMember,
			(response) => {
				setMember(response);
				setTopAlertMessage(selectedTag ? "Tag updated successfully" : "Tag added successfully");
				setTopAlertStatus("success");
				resetModals();
				setTimeout(() => setTopAlertMessage(""), 2000);
			},
			(error) => {
				setErrors(error);
				setTopAlertMessage("Failed to update tags");
				setTopAlertStatus("error");
			},
			() => setIsFetching(false)
		);
	};

	const handleDelete = () => {
		const updatedTags = member.tags?.filter((t) => t.id !== selectedTag.id);

		setIsFetching(true);
		putMemberUpdateAPI(
			{...member, address_line_1: member.addressLine1, tags: updatedTags},
			(response) => {
				setMember(response);
				setTopAlertMessage("Tag deleted successfully");
				setTopAlertStatus("success");
				resetModals();
				setTimeout(() => setTopAlertMessage(""), 2000);
			},
			(error) => {
				setErrors(error);
				setTopAlertMessage("Failed to delete tag");
				setTopAlertStatus("error");
			},
			() => setIsFetching(false)
		);
	};

	useEffect(() => {
		getMemberDetailAPI(
			id,
			onMemberDetailSuccess,
			onMemberDetailError,
			onMemberDetailDone
		);
	}, [id]);

	return (
		<div className="space-y-6">
			<section className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">
				<div className="flex justify-between items-center mb-6">
					<h3 className="text-lg font-bold text-textDark flex items-center">
						<FontAwesomeIcon icon={faTags} className="mr-3 text-primaryColor" />
						Tags
					</h3>
					<button
						onClick={() => setShowAddModal(true)}
						className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-dark">
						<FontAwesomeIcon icon={faPlus} className="mr-2" />
						Add Tag
					</button>
				</div>

				{isLoading ? (
            <PageLoadingContent displayMessage="Loading tags..." />
        ) : (

				<div className="mt-6">
					{member && member.tags && member.tags.length > 0 && member.tags !== null  ? (

<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
							{member.tags?.map((tag) => (
								<TagCard
									key={tag.id}
									tag={tag}
									onEdit={() => {
										setSelectedTag(tag);
										setShowEditModal(true);
									}}
									onDelete={() => {
										setSelectedTag(tag);
										setShowDeleteModal(true);
									}}
								/>
							))}
						</div>

						
					) : (
						<div className="text-center py-12 bg-gray-50 rounded-lg">
							<div className="w-12 h-12 mx-auto rounded-xl bg-main flex items-center justify-center mb-4">
								<FontAwesomeIcon icon={faTags} className="w-6 h-6 text-white" />
							</div>
							<h3 className="text-lg font-semibold text-gray-900 mb-2">
								No Tags
							</h3>
							<p className="text-sm text-gray-500">
								Click "Add Tag" to assign tags to this member.
							</p>
						</div>
					)}
				</div>
		)}
			</section>

			<Modal isOpen={showAddModal} onClose={resetModals} header="Add New Tag">
				<TagForm
					onSubmit={handleSubmit}
					errors={errors}
					isSubmitting={isFetching}
				/>
			</Modal>

			<Modal isOpen={showEditModal} onClose={resetModals} header="Edit Tag">
				<TagForm
					tag={selectedTag}
					onSubmit={handleSubmit}
					errors={errors}
					isSubmitting={isFetching}
				/>
			</Modal>

			<Modal isOpen={showDeleteModal} onClose={resetModals} header="Delete Tag">
				<div className="space-y-4">
					<p className="text-sm text-gray-500">
						Are you sure you want to delete this tag? This action cannot be
						undone.
					</p>
					<div className="flex justify-end gap-3">
						<button
							onClick={resetModals}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
							Cancel
						</button>
						<button
							onClick={handleDelete}
							disabled={isFetching}
							className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 disabled:opacity-50">
							{isFetching ? "Deleting..." : "Delete Tag"}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}

function TagForm({tag, errors: formErrors, onSubmit, isSubmitting}) {
	// Local state for form
	const [text, setText] = useState(tag?.text || "");
	const [description, setDescription] = useState(tag?.description || "");
	const [errors, setErrors] = useState(formErrors || {});

	// Update errors when props change
	useEffect(() => {
		setErrors(formErrors);
	}, [formErrors]);

	const handleSubmit = (e) => {
		e.preventDefault();
		onSubmit({text, description});
	};

	return (
		<form onSubmit={handleSubmit} className="space-y-4">
			<FormErrorBox errors={errors} />
			<p className="pb-4">
				Please fill out all the required fields before submitting this form.
			</p>

			<FormInputField
				label="Text"
				name="text"
				type="text"
				placeholder="Input text"
				value={text}
				errorText={errors?.text}
				helpText=""
				onChange={(e) => setText(e.target.value)}
				isRequired={true}
				maxWidth="275px"
			/>

			<FormTextareaField
				label="Description (Optional)"
				name="description"
				placeholder="Description input"
				value={description}
				errorText={errors?.description}
				helpText=""
				onChange={(e) => setDescription(e.target.value)}
				isRequired={false}
				maxWidth="100%"
				rows={2}
			/>

			<div className="flex justify-end gap-3 pt-4">
				<button
					type="button"
					onClick={() => onSubmit(null)}
					className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border 
                    border-gray-300 rounded-md hover:bg-gray-50">
					Cancel
				</button>
				<button
					type="submit"
					disabled={isSubmitting}
					className="px-4 py-2 text-sm font-medium text-white bg-blue-600 
                    rounded-md hover:bg-blue-700 disabled:opacity-50">
					{isSubmitting ? "Saving..." : tag ? "Save Changes" : "Add Tag"}
				</button>
			</div>
		</form>
	);
}

export default AdminMemberDetailForTags;
