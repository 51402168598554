import AdminNutritionPlanUpdate from "../Components/Admin/Member/NutritionPlan/Update";
import AdminNutritionPlanSubmissionForm from "../Components/Admin/Member/NutritionPlan/DetailSubmissionForm";
import AdminNutritionPlanDetail from "../Components/Admin/Member/NutritionPlan/Detail";
import AdminNutritionPlanList from "../Components/Admin/Member/NutritionPlan/List";
import AdminNutritionPlanAdd from "../Components/Admin/Member/NutritionPlan/Add";
import AdminFitnessPlanUpdate from "../Components/Admin/Member/FitnessPlan/Update";
import AdminFitnessPlanSubmissionForm from "../Components/Admin/Member/FitnessPlan/DetailSubmissionForm";
import AdminFitnessPlanDetail from "../Components/Admin/Member/FitnessPlan/Detail";
import AdminFitnessPlanList from "../Components/Admin/Member/FitnessPlan/List";
import AdminFitnessPlanAdd from "../Components/Admin/Member/FitnessPlan/Add";
import AdminOfferUpdate from "../Components/Admin/Offer/Update";
import AdminOfferDetail from "../Components/Admin/Offer/Detail";
import AdminOfferList from "../Components/Admin/Offer/List";
import AdminOfferAdd from "../Components/Admin/Offer/Add";
import AdminVideoContentUpdate from "../Components/Admin/VideoCollection/VideoContent/Update";
import AdminVideoContentDetail from "../Components/Admin/VideoCollection/VideoContent/Detail";
import AdminVideoContentAdd from "../Components/Admin/VideoCollection/VideoContent/Add";
import AdminVideoContentList from "../Components/Admin/VideoCollection/VideoContent/List";
import AdminVideoCollectionUpdate from "../Components/Admin/VideoCollection/Update";
import AdminVideoCollectionDetail from "../Components/Admin/VideoCollection/Detail";
import AdminVideoCollectionAdd from "../Components/Admin/VideoCollection/Add";
import AdminVideoCollectionList from "../Components/Admin/VideoCollection/List";
import AdminVideoCategoryUpdate from "../Components/Admin/VideoCategory/Update";
import AdminVideoCategoryDetail from "../Components/Admin/VideoCategory/Detail";
import AdminVideoCategoryList from "../Components/Admin/VideoCategory/List";
import AdminVideoCategoryAdd from "../Components/Admin/VideoCategory/Add";
import AdminExerciseUpdate from "../Components/Admin/Exercise/Update";
import AdminExerciseAdd from "../Components/Admin/Exercise/Add";
import AdminExerciseDetail from "../Components/Admin/Exercise/Detail";
import AdminExerciseList from "../Components/Admin/Exercise/List";
import AdminMemberTagList from "../Components/Admin/Member/DetailForTags";
import AdminMemberUpdate from "../Components/Admin/Member/Update";
import AdminMemberDetail from "../Components/Admin/Member/Detail";
import AdminMemberList from "../Components/Admin/Member/List";
import AdminMemberAdd from "../Components/Admin/Member/Add";
import AdminDashboard from "../Components/Admin/Dashboard";
import AdminTrainingProgramList from "../Components/Admin/TrainingProgram/list";
import AdminTrainingProgramAdd from "../Components/Admin/TrainingProgram/add";
import AdminWorkoutList from "../Components/Admin/Workouts/list";
import AdminWorkoutAdd from "../Components/Admin/Workouts/add";
import AdminWorkoutDetail from "../Components/Admin/Workouts/Detail";
import AdminWorkoutUpdate from "../Components/Admin/Workouts/update";
import AdminQuestionnaireList from "../Components/Admin/questionnaire/List";
import AdminQuestionnaireAdd from "../Components/Admin/questionnaire/Add";
import AdminQuestionnaireDetail from "../Components/Admin/questionnaire/Detail";
import AdminQuestionnaireUpdate from "../Components/Admin/questionnaire/Update";
import AdminTPDetail from "../Components/Admin/TrainingProgram/Detail";
import AdminTPDetailUpdate from "../Components/Admin/TrainingProgram/Update";
import AdminFitnessChallengeAdd from "../Components/Admin/FitnessChallenge/add";
import FitnessChallengeList from "../Components/Admin/FitnessChallenge/list";
import AdminFitnessChallengeDetail from "../Components/Admin/FitnessChallenge/Detail";
import LeaderboardGlobalTabularList from "../Components/Admin/FitnessChallenge/leaderboard";
import AdminFitnessChallengeUpdate from "../Components/Admin/FitnessChallenge/update";
import AdminFitnessChallengeAssignBadges from "../Components/Admin/FitnessChallenge/AssignBadges";
import AdminOrganizationDetail from "../Components/Admin/organization/Detail";
import AdminOrganizationUpdate from "../Components/Admin/organization/update";
import AdminMemberProfileDetail from "../Components/Admin/Member/DetailForProfile";
import AdminBadgeAdd from "../Components/Admin/Badges/Add";
import AdminBadgeList from "../Components/Admin/Badges/List";
import AdminBadgeDetail from "../Components/Admin/Badges/Detail";
import AdminBadgeUpdate from "../Components/Admin/Badges/Update";
import AdminLevelAdd from "../Components/Admin/Levels/Add";
import AdminLevelList from "../Components/Admin/Levels/List";
import AdminLevelDetail from "../Components/Admin/Levels/Detail";
import AdminLevelUpdate from "../Components/Admin/Levels/Update";
import AdminTipAdd from "../Components/Admin/Tips/Add";
import AdminTipsList from "../Components/Admin/Tips/List";
import AdminTipDetail from "../Components/Admin/Tips/Detail";
import AdminTipUpdate from "../Components/Admin/Tips/Update";

import MemberLeaderboardGlobalTabularList from "../Components/Member/Biometric/Leaderboard/Global/TabularList";
import MemberLeaderboardPersonal from "../Components/Member/Biometric/Leaderboard/Personal/Personal";
import MemberSummary from "../Components/Member/Biometric/Summary/View";
import MemberDataPointHistoricalGraphicalList from "../Components/Member/Biometric/Historical/GraphicalList";
import MemberDataPointHistoricalTabularList from "../Components/Member/Biometric/Historical/TabularList";
import MemberBiometricLaunchpad from "../Components/Member/Biometric/Launchpad";
import MemberNutritionPlanUpdate from "../Components/Member/NutritionPlan/Update";
import MemberNutritionPlanSubmissionForm from "../Components/Member/NutritionPlan/DetailSubmissionForm";
import MemberNutritionPlanDetail from "../Components/Member/NutritionPlan/Detail";
import MemberNutritionPlanList from "../Components/Member/NutritionPlan/List";
import MemberNutritionPlanAdd from "../Components/Member/NutritionPlan/Add";
import MemberFitnessPlanUpdate from "../Components/Member/FitnessPlan/Update";
import MemberFitnessPlanSubmissionForm from "../Components/Member/FitnessPlan/DetailSubmissionForm";
import MemberFitnessPlanDetail from "../Components/Member/FitnessPlan/Detail";
import MemberFitnessPlanList from "../Components/Member/FitnessPlan/List";
import MemberFitnessPlanAdd from "../Components/Member/FitnessPlan/Add";
import MemberExerciseDetail from "../Components/Member/Exercise/Detail";
import MemberExerciseList from "../Components/Member/Exercise/List";
import MemberDashboard from "../Components/Member/Dashboard";
import MemberWorkoutList from "../Components/Member/Workouts/list";
import MemberWorkoutAdd from "../Components/Member/Workouts/add";
import MemberWorkoutDetail from "../Components/Member/Workouts/Detail";
import MemberWorkoutEdit from "../Components/Member/Workouts/update";
import MemberTrainingProgramList from "../Components/Member/TrainingProgram/list";
import MemberTrainingProgramAdd from "../Components/Member/TrainingProgram/add";
import MemberTPUpdate from "../Components/Member/TrainingProgram/Update";
import FitenessChallengeMemberList from "../Components/Member/FitnessChallenge/list";
import MemberFitnessChallengeDetail from "../Components/Member/FitnessChallenge/Detail";
import MemberLeaderboardGlobalTabularListForChallenge from "../Components/Member/FitnessChallenge/leaderboard";
import UserPointsHistory from "../Components/Member/FitnessChallenge/points";
import MemberPreviousLessonsList from "../Components/Member/PreviousLessonsOfTheWeek/List";
import MemberPreviousLessonsDetail from "../Components/Member/PreviousLessonsOfTheWeek/Detail";
import MemberPreviousTechniquesList from "../Components/Member/PreviousTechniquesOfTheWeek/List";
import MemberPreviousTechniquesDetail from "../Components/Member/PreviousTechniquesOfTheWeek/Detail";
import VideoPlayer from "../Components/Member/Video/VideoPlayer";
import CollectionDetail from "../Components/Member/Video/CollectionDetail";
import CategoryDetail from "../Components/Member/Video/CategoryDetail";
import VideoHomepage from "../Components/Member/Video/Categories";

import TwoFactorAuthenticationWizardStep1 from "../Components/Gateway/2FA/Step1";
import TwoFactorAuthenticationWizardStep2 from "../Components/Gateway/2FA/Step2";
import TwoFactorAuthenticationWizardStep3 from "../Components/Gateway/2FA/Step3";
import TwoFactorAuthenticationValidateOnLogin from "../Components/Gateway/2FA/ValidateOnLogin";
import LogoutRedirector from "../Components/Gateway/LogoutRedirector";
import Login from "../Components/Gateway/Login";
import Register from "../Components/Gateway/Register";
import RegisterSuccessful from "../Components/Gateway/RegisterSuccessful";
import IndexStaticPage from "../Components/Gateway/Index/Index";
import EmailVerification from "../Components/Gateway/EmailVerification";
import ForgotPassword from "../Components/Gateway/ForgotPassword";
import PasswordReset from "../Components/Gateway/PasswordReset";

import AccountTwoFactorAuthenticationDetail from "../Components/Account/2FA/View";
import AccountEnableTwoFactorAuthentication from "../Components/Account/2FA/EnableView";
import AccountFriendList from "../Components/Account/Friend/Friend";
import AccountMoreOperationAvatar from "../Components/Account/More/Operation/Avatar/Avatar";
import AccountMoreOperationSubscribe from "../Components/Account/More/Operation/Subscribe/Subscribe";
import AccountMoreLaunchpad from "../Components/Account/More/Launchpad";
import AccountWearableTechLaunchpad from "../Components/Account/WearableTech/Launchpad";
import AccountSubscriptionDetailAndCancel from "../Components/Account/Subscription/Subscription";
import AccountInvoiceList from "../Components/Account/Subscription/Invoice/List";
import AccountTagList from "../Components/Account/DetailForTags";
import AccountUpdate from "../Components/Account/Update";
import AccountChangePassword from "../Components/Account/ChangePassword";
import UserProfile from "../Components/Account/User/UserProfile";

import PrivacyStaticPage from "../Components/Misc/Privacy";
import TermsOfServiceStaticPage from "../Components/Misc/TOS";
import NotFoundError from "../Components/Misc/NotFoundError";
import Splash from "../Components/Misc/Splash";
import Adrenaline12WeekCombatFitnessProgram from "../Webpages/Adrenaline12WeekCombatFitnessProgram";
import AdrenalineMMATraining from "../Webpages/AdrenalineMMACombatFitnessTrainingFramework";
import ScienceOfCombatFitness from "../Webpages/ScienceOfCombactFitness";
import BlueLevelBadgeBenefits from "../Webpages/BlueLevelBadgeBenefits";

export const routeConfig = {
	admin: [
		// Dashboard
		{
			path: "dashboard",
			component: AdminDashboard,
		},

		// Offers
		{
			path: "offers",
			component: AdminOfferList,
		},
		{
			path: "offer/add",
			component: AdminOfferAdd,
		},
		{
			path: "offer/:id",
			component: AdminOfferDetail,
		},
		{
			path: "offer/:id/update",
			component: AdminOfferUpdate,
		},

		// Member Management
		{
			path: "members",
			component: AdminMemberList,
		},
		{
			path: "members/add",
			component: AdminMemberAdd,
		},
		{
			path: "member/:id",
			component: AdminMemberDetail,
		},
		{
			path: "member/:id/update",
			component: AdminMemberUpdate,
		},
		{
			path: "member/:id/tags",
			component: AdminMemberTagList,
		},
		{
			path: "member/:id/profile",
			component: AdminMemberProfileDetail,
		},

		// Nutrition Plans
		{
			path: "member/:uid/nutrition-plan/:id/update",
			component: AdminNutritionPlanUpdate,
		},

		{
			path: "member/:uid/nutrition-plan/:id/submission-form",
			component: AdminNutritionPlanSubmissionForm,
		},

		{
			path: "member/:uid/nutrition-plan/:id",
			component: AdminNutritionPlanDetail,
		},

		{
			path: "member/:uid/nutrition-plans/add",
			component: AdminNutritionPlanAdd,
		},

		{
			path: "member/:uid/nutrition-plans",
			component: AdminNutritionPlanList,
		},

		// Fitness Plans
		{
			path: "member/:uid/fitness-plan/:id/update",
			component: AdminFitnessPlanUpdate,
		},

		{
			path: "member/:uid/fitness-plan/:id/submission-form",
			component: AdminFitnessPlanSubmissionForm,
		},

		{
			path: "member/:uid/fitness-plan/:id",
			component: AdminFitnessPlanDetail,
		},
		{
			path: "member/:uid/fitness-plans/add",
			component: AdminFitnessPlanAdd,
		},

		{
			path: "member/:uid/fitness-plans",
			component: AdminFitnessPlanList,
		},

		// Video Management
		{
			path: "video-categories",
			component: AdminVideoCategoryList,
		},
		{
			path: "video-category/add",
			component: AdminVideoCategoryAdd,
		},
		{
			path: "video-category/:id",
			component: AdminVideoCategoryDetail,
		},
		{
			path: "video-category/:id/update",
			component: AdminVideoCategoryUpdate,
		},
		{
			path: "video-collections",
			component: AdminVideoCollectionList,
		},
		{
			path: "video-collections/add",
			component: AdminVideoCollectionAdd,
		},
		{
			path: "video-collection/:vcid",
			component: AdminVideoCollectionDetail,
		},
		{
			path: "video-collection/:vcid/update",
			component: AdminVideoCollectionUpdate,
		},
		{
			path: "video-collection/:vcid/video-content/:vconid/update",
			component: AdminVideoContentUpdate,
		},
		{
			path: "video-collection/:vcid/video-content/:vconid",
			component: AdminVideoContentDetail,
		},
		{
			path: "video-collection/:vcid/video-contents/add",
			component: AdminVideoContentAdd,
		},
		{
			path: "video-collection/:vcid/video-contents",
			component: AdminVideoContentList,
		},

		// Exercise Management
		{
			path: "exercises",
			component: AdminExerciseList,
		},
		{
			path: "exercises/add",
			component: AdminExerciseAdd,
		},
		{
			path: "exercise/:id",
			component: AdminExerciseDetail,
		},
		{
			path: "exercise/:id/update",
			component: AdminExerciseUpdate,
		},

		// Training Programs
		{
			path: "training-program",
			component: AdminTrainingProgramList,
		},
		{
			path: "training-program/add",
			component: AdminTrainingProgramAdd,
		},
		{
			path: "training-program/:id",
			component: AdminTPDetail,
		},
		{
			path: "training-program/:id/edit",
			component: AdminTPDetailUpdate,
		},

		// Workouts
		{
			path: "workouts",
			component: AdminWorkoutList,
		},
		{
			path: "workouts/add",
			component: AdminWorkoutAdd,
		},
		{
			path: "workouts/:id",
			component: AdminWorkoutDetail,
		},
		{
			path: "workouts/:id/update",
			component: AdminWorkoutUpdate,
		},

		// Questionnaires
		{
			path: "questions",
			component: AdminQuestionnaireList,
		},
		{
			path: "questions/add",
			component: AdminQuestionnaireAdd,
		},
		{
			path: "questions/:id",
			component: AdminQuestionnaireDetail,
		},
		{
			path: "questions/:id/update",
			component: AdminQuestionnaireUpdate,
		},

		// Fitness Challenges
		{
			path: "fitness-challenges",
			component: FitnessChallengeList,
		},
		{
			path: "fitness-challenge/add",
			component: AdminFitnessChallengeAdd,
		},
		{
			path: "fitness-challenge/:id",
			component: AdminFitnessChallengeDetail,
		},
		{
			path: "fitness-challenge/:id/update",
			component: AdminFitnessChallengeUpdate,
		},
		{
			path: "fitness-challenge/:id/leaderboard",
			component: LeaderboardGlobalTabularList,
		},
		{
			path: "fitness-challenge/:id/assign-badge",
			component: AdminFitnessChallengeAssignBadges,
		},

		// Organization
		{
			path: "organization",
			component: AdminOrganizationDetail,
		},
		{
			path: "organization/update",
			component: AdminOrganizationUpdate,
		},

		// Badges
		{
			path: "badges",
			component: AdminBadgeList,
		},
		{
			path: "badges/add",
			component: AdminBadgeAdd,
		},

		{
			path: "badges/:id",
			component: AdminBadgeDetail,
		},

		{
			path: "badges/:id/edit",
			component: AdminBadgeUpdate,
		},

		// Levels
		{
			path: "levels",
			component: AdminLevelList,
		},
		{
			path: "levels/add",
			component: AdminLevelAdd,
		},

		{
			path: "levels/:id",
			component: AdminLevelDetail,
		},

		{
			path: "levels/:id/edit",
			component: AdminLevelUpdate,
		},

		// Tips
		{
			path: "tips",
			component: AdminTipsList,
		},
		{
			path: "tips/add",
			component: AdminTipAdd,
		},

		{
			path: "tips/:id",
			component: AdminTipDetail,
		},

		{
			path: "tips/:id/update",
			component: AdminTipUpdate,
		},
	],

	member: [
		// Dashboard
		{
			path: "/dashboard",
			component: MemberDashboard,
		},

		// Biometrics
		{
			path: "/biometrics/leaderboard/global",
			component: MemberLeaderboardGlobalTabularList,
		},
		{
			path: "/biometrics/leaderboard/personal",
			component: MemberLeaderboardPersonal,
		},
		{
			path: "/biometrics/summary",
			component: MemberSummary,
		},
		{
			path: "/biometrics/history/graphview",
			component: MemberDataPointHistoricalGraphicalList,
		},
		{
			path: "/biometrics/history/tableview",
			component: MemberDataPointHistoricalTabularList,
		},
		{
			path: "/biometrics",
			component: MemberBiometricLaunchpad,
		},

		// Nutrition Plans
		{
			path: "/nutrition-plan/:id/update",
			component: MemberNutritionPlanUpdate,
		},
		{
			path: "/nutrition-plan/:id/submission-form",
			component: MemberNutritionPlanSubmissionForm,
		},
		{
			path: "/nutrition-plan/:id",
			component: MemberNutritionPlanDetail,
		},
		{
			path: "/nutrition-plans/add",
			component: MemberNutritionPlanAdd,
		},
		{
			path: "/nutrition-plans",
			component: MemberNutritionPlanList,
		},

		// Fitness Plans
		{
			path: "/fitness-plan/:id/update",
			component: MemberFitnessPlanUpdate,
		},
		{
			path: "/fitness-plan/:id/submission-form",
			component: MemberFitnessPlanSubmissionForm,
		},
		{
			path: "/fitness-plan/:id",
			component: MemberFitnessPlanDetail,
		},
		{
			path: "/fitness-plans/add",
			component: MemberFitnessPlanAdd,
		},
		{
			path: "/fitness-plans",
			component: MemberFitnessPlanList,
		},

		// Exercises
		{
			path: "/exercise/:id",
			component: MemberExerciseDetail,
		},
		{
			path: "/exercises",
			component: MemberExerciseList,
		},

		// Workouts
		{
			path: "/workouts",
			component: MemberWorkoutList,
		},
		{
			path: "/workouts/add",
			component: MemberWorkoutAdd,
		},
		{
			path: "/workouts/:id",
			component: MemberWorkoutDetail,
		},
		{
			path: "/workouts/:id/update",
			component: MemberWorkoutEdit,
		},

		// Training Programs
		{
			path: "/training-programs",
			component: MemberTrainingProgramList,
		},
		{
			path: "/training-program/add",
			component: MemberTrainingProgramAdd,
		},
		{
			path: "/training-program/:id/update",
			component: MemberTPUpdate,
		},

		// Fitness Challenges
		{
			path: "/fitness-challenge",
			component: FitenessChallengeMemberList,
		},
		{
			path: "/fitness-challenge/:id",
			component: MemberFitnessChallengeDetail,
		},
		{
			path: "/fitness-challenge/:id/leaderboard",
			component: MemberLeaderboardGlobalTabularListForChallenge,
		},
		{
			path: "/fitness-challenge/:id/points",
			component: UserPointsHistory,
		},

		// Previous Lessons & Techniques
		{
			path: "/previous-week-lessons",
			component: MemberPreviousLessonsList,
		},
		{
			path: "/previous-week-lesson/:id",
			component: MemberPreviousLessonsDetail,
		},
		{
			path: "/previous-week-techniques",
			component: MemberPreviousTechniquesList,
		},
		{
			path: "/previous-week-technique/:id",
			component: MemberPreviousTechniquesDetail,
		},

		// Videos
		{
			path: "/videos",
			component: VideoHomepage,
		},
		{
			path: "/videos/category/:categoryId",
			component: CategoryDetail,
		},
		{
			path: "/videos/collection/:collectionId",
			component: CollectionDetail,
		},
		{
			path: "/videos/watch/:videoId",
			component: VideoPlayer,
		},
	],

	account: [
		// Profile & Settings
		{
			path: "/account",
			component: UserProfile,
		},
		{
			path: "/account/update",
			component: AccountUpdate,
		},
		{
			path: "/account/change-password",
			component: AccountChangePassword,
		},
		{
			path: "/account/tags",
			component: AccountTagList,
		},

		// Friends
		{
			path: "/account/friends",
			component: AccountFriendList,
		},

		// More Operations
		{
			path: "/account/more",
			component: AccountMoreLaunchpad,
		},
		{
			path: "/account/more/avatar",
			component: AccountMoreOperationAvatar,
		},
		{
			path: "/account/more/subscribe",
			component: AccountMoreOperationSubscribe,
		},

		// Wearable Tech
		{
			path: "/account/wearable-tech",
			component: AccountWearableTechLaunchpad,
		},

		// Subscription
		{
			path: "/account/subscription",
			component: AccountSubscriptionDetailAndCancel,
		},
		{
			path: "/account/subscription/invoices",
			component: AccountInvoiceList,
		},

		// 2FA
		{
			path: "/account/2fa",
			component: AccountTwoFactorAuthenticationDetail,
		},
		{
			path: "/account/2fa/enable",
			component: AccountEnableTwoFactorAuthentication,
		},
	],

	public: [
		// Auth
		{
			path: "/login",
			component: Login,
			redirectIfAuth: true,
		},
		{
			path: "/register",
			component: Register,
			redirectIfAuth: true,
		},
		{
			path: "/register-successful",
			component: RegisterSuccessful,
			redirectIfAuth: true,
		},
		{
			path: "/forgot-password",
			component: ForgotPassword,
		},
		{
			path: "/password-reset",
			component: PasswordReset,
		},
		{
			path: "/verify",
			component: EmailVerification,
		},
		{
			path: "/logout",
			component: LogoutRedirector,
		},

		// 2FA
		{
			path: "/login/2fa/step-1",
			component: TwoFactorAuthenticationWizardStep1,
		},
		{
			path: "/login/2fa/step-2",
			component: TwoFactorAuthenticationWizardStep2,
		},
		{
			path: "/login/2fa/step-3",
			component: TwoFactorAuthenticationWizardStep3,
		},
		{
			path: "/login/2fa",
			component: TwoFactorAuthenticationValidateOnLogin,
		},

		// Static Pages
		{
			path: "/terms-of-service",
			component: TermsOfServiceStaticPage,
		},
		{
			path: "/privacy-policy",
			component: PrivacyStaticPage,
		},
		{
			path: "/adrenaline-combat-framework",
			component: AdrenalineMMATraining,
		},
		{
			path: "/the-12-week-program",
			component: Adrenaline12WeekCombatFitnessProgram,
		},
		{
			path: "/science-of-adrenaline-combat-fitness",
			component: ScienceOfCombatFitness,
		},
		{
			path: "/blue-level-badge-benefits",
			component: BlueLevelBadgeBenefits,
		},
		{
			path: "/splash-page",
			component: Splash,
		},
		{
			path: "/",
			component: IndexStaticPage,
		}
	],

	errors: [
		{
			path: "*",
			component: NotFoundError,
		},
	],
};
