import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import QRCode from "qrcode.react";
import Scroll from "react-scroll";

import FormErrorBox from "../../Reusable/FormErrorBox";
import { postGenerateOTP } from "../../../API/gateway";
import { currentOTPResponseState, currentUserState } from "../../../AppState";

function TwoFactorAuthenticationWizardStep2() {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [otpResponse, setOtpResponse] = useRecoilState(currentOTPResponseState);
  const [errors, setErrors] = useState({});
  const [forceURL, setForceURL] = useState("");

  function onGenerateOPTSuccess(response) {
    console.log("onGenerateOPTSuccess: Starting...");
    setOtpResponse(response);
  }

  function onGenerateOPTError(apiErr) {
    console.log("onGenerateOPTError: Starting...");
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0);

      if (!otpResponse) {
        postGenerateOTP(
          onGenerateOPTSuccess,
          onGenerateOPTError,
          () => console.log("onGenerateOPTDone: Starting...")
        );
      }
    }

    return () => (mounted = false);
  }, [otpResponse]);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-zinc-900 to-backgroundDark py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <div className="bg-zinc-800/30 backdrop-blur-sm rounded-2xl p-8 shadow-xl animate-fade-in">
          {/* Progress Bar */}
          <div className="bg-zinc-900/50 rounded-xl p-6 mb-8">
            <p className="text-gray-300 text-lg mb-2">Step 2 of 3</p>
            <div className="w-full bg-gray-700 rounded-full h-2">
              <div 
                className="bg-primary w-2/3 h-2 rounded-full animate-pulse-glow"
                style={{ transition: 'width 0.5s ease-in-out' }}
              ></div>
            </div>
          </div>

          {/* Header */}
          <h1 className="text-3xl font-bold text-center text-lightShade mb-8 font-primary">
            Setup Two-Factor Authentication
          </h1>
          
          <FormErrorBox errors={errors} />

          {/* Description */}
          <p className="text-gray-400 mb-8">
            With your 2FA application open, please scan the following QR code with your device 
            and click next when ready.
          </p>

          {/* QR Code Container */}
          <div className="flex justify-center mb-8">
            <div className="bg-white p-6 rounded-xl shadow-lg transform hover:scale-105 transition-transform duration-300">
              {otpResponse && (
                <div className="space-y-4">
                  <QRCode
                    value={otpResponse.optAuthURL}
                    size={250}
                    className="mx-auto"
                    level="H"
                  />
                  <p className="text-center text-gray-600 font-secondary">
                    Scan with your app
                  </p>
                </div>
              )}
              {!otpResponse && (
                <div className="w-[250px] h-[250px] animate-pulse bg-gray-200 rounded-lg">
                  <div className="flex items-center justify-center h-full">
                    <p className="text-gray-400">Loading...</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between mt-8">
            <Link
              to="/login/2fa/step-1"
              className="inline-flex items-center px-6 py-3 rounded-xl bg-zinc-700 text-gray-200 hover:bg-zinc-600 transition-colors"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back
            </Link>
            <Link
              to="/login/2fa/step-3"
              className="inline-flex items-center px-6 py-3 rounded-xl bg-primary-DEFAULT text-lightShade hover:bg-primary-hover transition-colors animate-pulse-glow"
            >
              Next
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Link>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center mt-8 text-gray-400">
          <p>© 2024 The Adrenaline MMA Training & Fitness Center Team</p>
        </div>
      </div>
    </div>
  );
}

export default TwoFactorAuthenticationWizardStep2;