import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../AppState";
import { routeConfig } from "../../routes/routeConfig";

function AnonymousCurrentUserRedirector() {
  const currentUser = useRecoilValue(currentUserState);
  const location = useLocation();

  // Get all public paths from routeConfig
  const publicPaths = routeConfig.public.map(route => route.path);

  // Check if current path matches any public path
  const isPublicPath = publicPaths.some(path => {
    // Convert route path pattern to regex
    const pathPattern = path.replace(/:\w+/g, '[^/]+');
    const regex = new RegExp(`^${pathPattern}$`);
    return regex.test(location.pathname);
  });

  // If it's a public path, don't redirect
  if (isPublicPath) {
    return null;
  }

  // For non-public paths, check authentication
  if (!currentUser && !isPublicPath) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return null;
}

export default AnonymousCurrentUserRedirector;