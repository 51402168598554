const convertToCSV = (data, options = {}) => {
    if (!data || !data.length) return '';

    // Default options
    const defaultOptions = {
        excludeFields: [
            'passwordHash', 'passwordHashAlgorithm', 'emailVerificationCode',
            'createdFromIpAddress', 'modifiedFromIpAddress', 'stripeSubscription',
            'stripeInvoices', 'healthAppConnections', 'badgesEarned', 'onboardingAnswers'
        ],
        dateFields: ['created_at', 'modified_at', 'subscription_started_at'],
        customHeaders: null,
        customTransforms: {}
    };

    const finalOptions = { ...defaultOptions, ...options };

    // Get all unique keys from the data, excluding specified fields
    const allKeys = Array.from(
        new Set(
            data.flatMap(obj => 
                Object.keys(obj).filter(key => 
                    !finalOptions.excludeFields.includes(key)
                )
            )
        )
    );

    // Use custom headers if provided, otherwise format the keys
    const headers = finalOptions.customHeaders || 
        allKeys.map(key => key.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        );

    // Convert data to rows
    const rows = data.map(item => {
        return allKeys.map(key => {
            let value = item[key];

            // Apply custom transforms if available
            if (finalOptions.customTransforms[key]) {
                value = finalOptions.customTransforms[key](value);
            }
            // Format dates
            else if (finalOptions.dateFields.includes(key) && value) {
                value = new Date(value).toLocaleDateString();
            }
            // Format boolean values
            else if (typeof value === 'boolean') {
                value = value ? 'Yes' : 'No';
            }
            // Format arrays
            else if (Array.isArray(value)) {
                value = value.join(', ');
            }
            // Handle null/undefined
            else if (value === null || value === undefined) {
                value = '';
            }

            // Escape commas and quotes in the value
            return `"${String(value).replace(/"/g, '""')}"`;
        });
    });

    // Combine headers and rows
    return [
        headers.map(h => `"${h}"`).join(','),
        ...rows.map(row => row.join(','))
    ].join('\n');
};

export default convertToCSV;