import React, {useEffect} from "react";
import Scroll from "react-scroll";
import {Link} from "react-router-dom";
import {
	faHandFist,
	faRunning,
	faPersonPraying,
	faListCheck,
	faHeartbeat,
	faUserNinja,
	faBalanceScale,
	faDumbbell,
	faFire,
	faArrowRight,
	faCheck,
	faPersonRunning,
	faStopwatch,
	faRepeat,
	faBookOpen,
	faClock,
	faGraduationCap,
	faSync,
	faArrowLeft,
	faForward,
	faUserGroup,
	faChartLine,
	faShieldHalved,
	faSyncAlt,
	faPersonWalking,
	faSignInAlt,
	faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
	faFacebook,
	faInstagram,
	faKickstarter,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AdrenalineMMATraining = () => {
	useEffect(() => {
		Scroll.animateScroll.scrollToTop();
	}, []);

	return (
		<div className="min-h-screen bg-gradient-to-b from-zinc-900 via-backgroundDark to-black text-textLight">
			{/* Navigation Bar */}
			<nav className="fixed top-0 w-full bg-black/80 backdrop-blur-md z-50">
				<div className="container mx-auto px-6 py-4">
					<div className="flex justify-between items-center">
						<div className="flex items-center space-x-16">
							<img src="logo.svg" alt="Logo" className="h-12 w-auto" />
						</div>
						<div className="flex items-center space-x-6">
							<Link
								to="/login"
								className="text-gray-300 hover:text-white transition-colors duration-300 flex items-center space-x-2 group">
								<FontAwesomeIcon
									icon={faSignInAlt}
									className="group-hover:rotate-12 transition-transform duration-300"
								/>
								<span>Login</span>
							</Link>
							<Link
								to="/register"
								className="bg-primary hover:bg-primary/30 px-6 py-2 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center space-x-2 shadow-lg shadow-primary/20">
								<FontAwesomeIcon
									icon={faUserPlus}
									className="group-hover:scale-110 transition-transform duration-300"
								/>
								<span>Get Started</span>
							</Link>
						</div>
					</div>
				</div>
			</nav>
			{/* Hero Section with Dynamic Background */}
			<section className="relative pt-28 pb-16 bg-gradient-to-b from-primary-dark to-darkShade">
				<div className="container mx-auto px-6 relative z-10">
					<div className="mx-auto max-w-5xl">
						<div className="space-y-8 text-center">
							<h1
								className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 
								font-bold tracking-tight leading-[1.1] 
								bg-gradient-to-r from-primary-light to-primary-dark 
								bg-clip-text text-white animate-fade-in">
								Adrenaline MMA Combat Fitness Training Framework
							</h1>
							<p className="text-xl md:text-2xl text-gray-300 leading-relaxed mx-auto animate-fade-in-up max-w-3xl">
								The Adrenaline high-intensity fitness training framework that
								focuses on body weight exercises, calisthenics, shadow boxing,
								core work and combat fitness tactics.
							</p>
						</div>
					</div>
				</div>
			</section>

			{/* Bodyweight Exercises Section */}
			<section className="py-16">
				<div className="container mx-auto px-6">
					{/* Main Content */}
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
							<h2 className="text-4xl font-bold bg-clip-text text-lightShade bg-gradient-to-r from-primary to-primary-light">
								Bodyweight
							</h2>
							<h3 className="text-lg italic sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								Exercises and Calisthenics
							</h3>
							<p className="mt-4 text-gray-300 text-lg leading-relaxed">
								The Adrenaline Blue level introduces fundamental punching
								combinations that form the foundation of striking techniques.
								Practice these combinations with focus on proper form, hip
								rotation, and maintaining your center line. Vary your tempo to
								enhance conditioning and develop muscle memory.
							</p>
						</div>
						<div className="grid md:grid-cols-2 gap-8">
							{[
								{
									title: "Fighting Stance and Movement",
									description:
										"The sources emphasize the importance of a proper fighting stance. This includes a bladed stance, weight distribution, and hand positioning. Practicing moving in all directions (forward, backward, left, and right) while maintaining the fighting stance can be a great bodyweight exercise. This can also incorporate a 'rocking' motion, transferring weight between legs. Adding faints, level changes, and slips can further enhance the workout.",
									icon: faUserNinja,
								},
								{
									title: "Forward and Backward Bounce",
									description:
										"This movement involves a light bouncing motion on the balls of your feet, moving forward and back, which helps with hiding movements before striking. This can be practiced as a dynamic warm-up exercise that also develops balance.",
									icon: faRunning,
								},
								{
									title: "Core Strength",
									description:
										"The sources highlight the importance of core engagement for powerful punches. While not explicitly stated, many of the exercises that involve stance and movement would engage the core.",
									icon: faFire,
								},
								{
									title: "Balance and Stability",
									description:
										"Maintaining a good stance and moving in various directions, while keeping hands up and elbows in, would train balance and stability. The sources repeatedly emphasize a 'light balance' with weight distributed between legs.",
									icon: faBalanceScale,
								},
							].map((exercise, index) => (
								<div
									key={index}
									className="group relative bg-zinc-800/30 backdrop-blur-sm p-8 rounded-xl 
                             border border-zinc-700/50 hover:border-primary/50 
                             transition-all duration-300 hover:shadow-lg hover:shadow-primary/10
                             animate-fade-in-up"
									style={{animationDelay: `${index * 0.1}s`}}>
									<div
										className="absolute inset-0 bg-gradient-to-r from-primary/5 to-primary-light/5 
                              opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-xl"
									/>

									<FontAwesomeIcon
										icon={exercise.icon}
										className="text-3xl text-primary-light mb-6 
                                        group-hover:text-primary transition-colors duration-300"
									/>

									<h3
										className="text-2xl font-semibold mb-4 text-primary-light 
                             group-hover:text-primary transition-colors duration-300">
										{exercise.title}
									</h3>

									<p className="text-gray-300 leading-relaxed relative z-10">
										{exercise.description}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>

			{/* Combat Fitness Elements Section */}
			<section className="py-16">
				<div className="container mx-auto px-6">
					{/* Main Content */}
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
							<h2 className="text-4xl font-bold bg-clip-text text-lightShade bg-gradient-to-r from-primary to-primary-light">
								Combat Fitness Elements
							</h2>
							<h3 className="text-lg italic sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								Punching Combinations
							</h3>
							<p className="mt-4 text-gray-300 text-lg leading-relaxed">
								The Adrenaline Blue level introduces fundamental punching
								combinations that form the foundation of striking techniques.
								Practice these combinations with focus on proper form, hip
								rotation, and maintaining your center line. Vary your tempo to
								enhance conditioning and develop muscle memory.
							</p>
							<div className="mt-6 flex flex-wrap gap-4">
								{[
									"Proper Form",
									"Hip Rotation",
									"Center Line",
									"Variable Tempo",
								].map((tip, index) => (
									<div
										key={index}
										className="flex items-center gap-2 px-4 py-2 bg-zinc-800/40 rounded-full border border-zinc-700/50">
										<FontAwesomeIcon
											icon={faCheck}
											className="text-primary-light text-sm"
										/>
										<span className="text-gray-300 text-sm font-medium">
											{tip}
										</span>
									</div>
								))}
							</div>
						</div>

						{/* Timeline with Image Split */}
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8 lg:gap-12">
							{/* Left: Timeline Content */}
							<div className="relative order-2 lg:order-1">
								{/* Main vertical line */}
								<div
									className="absolute left-[28px] sm:left-[35px] top-0 bottom-0 w-[2px] md:w-[3px] 
                         bg-gradient-to-b from-zinc-800 via-primary/20 to-zinc-800"
								/>

								{[
									{
										name: "Combination 1",
										moves: "Jab",
									},
									{
										name: "Combination 2",
										moves: "Jab, Cross",
									},
									{
										name: "Combination 3",
										moves: "Jab, Cross, Hook",
									},
									{
										name: "Combination 4",
										moves: "Jab, Cross, Hook, Cross",
									},
								].map((combo, index) => (
									<div
										key={index}
										className="relative pl-[70px] sm:pl-[90px] pb-12 sm:pb-16 last:pb-0 group">
										{/* Number indicator */}
										<div className="absolute left-0 top-0">
											<div
												className="w-[56px] h-[56px] sm:w-[72px] sm:h-[72px] 
                              bg-zinc-900/80 backdrop-blur-sm
                              border-2 border-zinc-800 
                              group-hover:border-primary
                              transition-colors duration-300
                              flex items-center justify-center
                              shadow-lg shadow-black/20">
												<span
													className="text-2xl sm:text-3xl font-bold 
                                 bg-gradient-to-br from-primary-light to-primary 
                                 bg-clip-text text-transparent">
													{index + 1}
												</span>
											</div>
										</div>

										{/* Horizontal connector */}
										<div
											className="absolute left-[56px] sm:left-[72px] top-[27px] sm:top-[35px] 
                            w-[14px] sm:w-[18px] h-[2px] sm:h-[3px] bg-zinc-800
                            group-hover:bg-primary/50 
                            transition-colors duration-300"
										/>

										{/* Content Card */}
										<div
											className="bg-zinc-900/40 backdrop-blur-md 
                            border border-zinc-800/50 
                            group-hover:border-zinc-700
                            transition-colors duration-300
                            p-4 sm:p-6 md:p-8 rounded-lg
                            shadow-xl shadow-black/10">
											{/* Header */}
											<div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 mb-4">
												<h4 className="text-lg sm:text-xl md:text-2xl font-bold text-white">
													{combo.name}
												</h4>
											</div>

											{/* Content */}
											<p className="text-gray-300 text-sm sm:text-base md:text-lg leading-relaxed">
												{combo.moves}
											</p>
										</div>
									</div>
								))}
							</div>

							{/* Right: Image Section */}
							<div className="order-1 lg:order-2">
								<div className="lg:sticky lg:top-8 w-full">
									<div className="relative aspect-[4/5] rounded-lg sm:rounded-xl overflow-hidden">
										<img
											src="/static/mma/mark.png"
											alt="MMA Fighter demonstrating punching technique"
											className="object-cover w-full h-full brightness-90"
											loading="eager"
										/>
										{/* Image Overlay */}
										<div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent" />

										{/* Info overlay */}
										<div className="absolute bottom-4 sm:bottom-6 left-4 sm:left-6 right-4 sm:right-6">
											<div className="bg-black/60 backdrop-blur-sm px-3 py-2 sm:px-4 sm:py-3 rounded-lg">
												<h4 className="text-base sm:text-lg font-medium text-primary-light mb-0.5 sm:mb-1">
													Blue Level
												</h4>
												<p className="text-xs sm:text-sm text-gray-300">
													Basic Punching Combinations
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="py-16">
				<div className="container mx-auto px-6">
					{/* Main Content */}
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
							<h2 className="text-4xl font-bold bg-clip-text text-lightShade bg-gradient-to-r from-primary to-primary-light">
								Combat Fitness Elements
							</h2>
							<h3 className="text-lg italic sm:text-xl md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								Knee Strikes and Kicks
							</h3>
							<p className="mt-4 text-gray-300 text-lg leading-relaxed">
								The Adrenaline Blue level introduces fundamental punching
								combinations that form the foundation of striking techniques.
								Practice these combinations with focus on proper form, hip
								rotation, and maintaining your center line. Vary your tempo to
								enhance conditioning and develop muscle memory.
							</p>
						</div>

						<div className="grid md:grid-cols-2 gap-8">
							{/* Knee Strikes Card */}
							<div
								className="group relative bg-zinc-800/30 backdrop-blur-sm p-8 rounded-xl 
                border border-zinc-700/50 hover:border-primary-light/50 
                transition-all duration-300 hover:shadow-lg hover:shadow-primary-light/10">
								{/* Header Section */}
								<div className="flex items-center justify-between mb-8">
									<div className="flex items-center gap-4">
										<div className="p-4 bg-primary-dark/20 rounded-lg">
											<FontAwesomeIcon
												icon={faUserNinja}
												className="text-3xl text-primary-light"
											/>
										</div>
										<div>
											<h3 className="text-2xl font-semibold text-primary-light">
												Knee Strikes
											</h3>
											<div className="flex gap-2 items-center mt-1">
												<FontAwesomeIcon
													icon={faClock}
													className="text-primary-light/70 text-sm"
												/>
												<span className="text-gray-400 text-sm">
													Training Focus
												</span>
											</div>
										</div>
									</div>
									<div className="hidden md:flex items-center gap-2 bg-primary-dark/20 px-4 py-2 rounded-lg">
										<FontAwesomeIcon
											icon={faFire}
											className="text-primary-light"
										/>
										<span className="text-gray-300">High Impact</span>
									</div>
								</div>

								<div className="grid md:grid-cols-2 gap-6">
									{/* Basic Technique Section */}
									<div className="bg-zinc-800/40 p-6 rounded-lg border border-zinc-700/30">
										<h4 className="text-lg font-medium text-primary-light mb-4 flex items-center gap-2">
											<FontAwesomeIcon
												icon={faBookOpen}
												className="text-primary-light/70"
											/>
											Basic Technique
										</h4>
										<div className="space-y-4">
											<p className="text-gray-300 leading-relaxed">
												Perform knee strikes with proper hip movement, utilizing
												both lead and rear leg variations.
											</p>
											<ul className="space-y-2">
												{[
													"Proper hip rotation",
													"Balance control",
													"Power generation",
												].map((item, index) => (
													<li
														key={index}
														className="flex items-center gap-2 text-gray-300">
														<FontAwesomeIcon
															icon={faCheck}
															className="text-primary-light/70 text-sm"
														/>
														{item}
													</li>
												))}
											</ul>
										</div>
									</div>

									{/* Advanced Integration Section */}
									<div className="bg-zinc-800/40 p-6 rounded-lg border border-zinc-700/30">
										<h4 className="text-lg font-medium text-primary-light mb-4 flex items-center gap-2">
											<FontAwesomeIcon
												icon={faGraduationCap}
												className="text-primary-light/70"
											/>
											Advanced Integration
										</h4>
										<div className="space-y-4">
											<p className="text-gray-300 leading-relaxed">
												Incorporate skip steps before knee strikes to add
												plyometric elements to your workout.
											</p>
											<ul className="space-y-2">
												{[
													"Dynamic movement patterns",
													"Combination flows",
													"Explosive power",
												].map((item, index) => (
													<li
														key={index}
														className="flex items-center gap-2 text-gray-300">
														<FontAwesomeIcon
															icon={faCheck}
															className="text-primary-light/70 text-sm"
														/>
														{item}
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>

								{/* Key Elements Section */}
								<div className="mt-6 pt-6 border-t border-zinc-700/30">
									<h4 className="text-sm uppercase tracking-wider text-gray-400 mb-4">
										Key Elements
									</h4>
									<div className="flex flex-wrap gap-3">
										{[
											{label: "Hip Movement", icon: faSync},
											{label: "Lead Leg", icon: faArrowRight},
											{label: "Rear Leg", icon: faArrowLeft},
											{label: "Skip Steps", icon: faForward},
										].map((tag, index) => (
											<span
												key={index}
												className="px-4 py-2 bg-primary-dark/20 rounded-lg text-sm text-primary-light 
                       flex items-center gap-2 hover:bg-primary-dark/30 transition-colors">
												<FontAwesomeIcon
													icon={tag.icon}
													className="text-primary-light/70"
												/>
												{tag.label}
											</span>
										))}
									</div>
								</div>
							</div>

							{/* Kicks Card */}
							<div
								className="group relative bg-zinc-800/30 backdrop-blur-sm p-8 rounded-xl 
                    border border-zinc-700/50 hover:border-primary-light/50 
                    transition-all duration-300 hover:shadow-lg hover:shadow-primary-light/10">
								<div className="space-y-6">
									<div className="flex items-center gap-4">
										<div className="p-3 bg-primary-dark/20 rounded-lg">
											<FontAwesomeIcon
												icon={faRunning}
												className="text-2xl text-primary-light"
											/>
										</div>
										<h3 className="text-2xl font-semibold text-primary-light">
											Kicks
										</h3>
									</div>

									<div className="space-y-4">
										{[
											{
												title: "Low Kicks",
												description:
													"Target the outside and inside of the lead leg with precise strikes.",
											},
											{
												title: "Body Kicks",
												description:
													"Execute in a smooth arc motion, using hip drive for maximum force.",
											},
											{
												title: "Push Kicks (Teep)",
												description:
													"Bring knee up and extend foot in a straight line for this defensive strike.",
											},
										].map((kick, index) => (
											<div
												key={index}
												className="bg-zinc-800/40 p-4 rounded-lg">
												<h4 className="text-lg font-medium text-primary-light mb-2">
													{kick.title}
												</h4>
												<p className="text-gray-300">{kick.description}</p>
											</div>
										))}

										<div className="mt-4 p-4 bg-primary-dark/10 border border-primary-dark/20 rounded-lg">
											<h4 className="text-lg font-medium text-primary-light mb-2">
												Key Principles
											</h4>
											<ul className="list-disc list-inside text-gray-300 space-y-2">
												<li>Step off the center line when kicking</li>
												<li>Practice proper arc movements</li>
												<li>Focus on counter-kick defense</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="py-24 bg-zinc-900">
				<div className="container mx-auto px-6">
					{/* Section Header */}
					<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
						<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<div className="space-y-4">
							<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
								Combat Fitness Elements
							</h2>
							<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								Defensive Movements
							</h3>
							<p className="text-gray-300 text-lg leading-relaxed">
								The sources emphasize the importance of head movement to avoid
								punches. These defensive techniques are crucial for both safety
								and counter-attacking opportunities.
							</p>
						</div>
					</div>

					{/* Content Grid */}
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 lg:gap-16">
						{/* Defensive Techniques */}
						<div className="order-2 lg:order-1">
							<div className="space-y-6">
								{[
									{
										title: "Slipping",
										description:
											"Practicing slipping to the outside of straight punches (jabs and crosses)",
										icon: faShieldHalved,
									},
									{
										title: "Rolling",
										description:
											"Rolling under hooks, while level changing, can be combined with counter punches.",
										icon: faSyncAlt,
									},
									{
										title: "Checking Leg Kicks",
										description:
											"Leg kicks can be defended by lifting the leg and using the shin as a shield, pointing the knee toward the incoming kick.",
										icon: faPersonWalking,
									},
								].map((technique, index) => (
									<div
										key={index}
										className="group bg-zinc-900/40 backdrop-blur-md 
                                     border border-zinc-800/50 
                                     hover:border-primary/30
                                     transition-all duration-300
                                     p-6 rounded-xl
                                     shadow-xl shadow-black/10
                                     hover:shadow-2xl hover:shadow-primary/5
                                     transform hover:-translate-y-1">
										<div className="flex items-start gap-4 mb-4">
											<div
												className="w-12 h-12 bg-gradient-to-br from-primary-light/20 to-primary-dark/20 
                                              rounded-lg flex items-center justify-center shrink-0
                                              group-hover:from-primary-light/30 group-hover:to-primary-dark/30
                                              transition-all duration-300">
												<span
													className="text-2xl font-bold 
                                                   bg-gradient-to-br from-primary-light to-primary 
                                                   bg-clip-text text-transparent">
													{index + 1}
												</span>
											</div>
											<h3
												className="text-xl font-bold 
                                             bg-gradient-to-r from-primary-light to-primary 
                                             bg-clip-text text-transparent">
												{technique.title}
											</h3>
										</div>
										<p className="text-gray-300 text-base leading-relaxed pl-16">
											{technique.description}
										</p>
									</div>
								))}

								{/* Additional Info Card */}
								<div
									className="bg-primary-dark/10 border border-primary-dark/20 
                                  p-6 rounded-xl mt-8">
									<h4 className="text-lg font-semibold text-primary-light mb-3">
										Movement After Combinations
									</h4>
									<p className="text-gray-300 leading-relaxed">
										After performing combinations, it is important to move your
										head or feet to avoid counterattacks. This can be done by
										slipping the head off the centerline or angling off to
										either side by moving the feet.
									</p>
								</div>
							</div>
						</div>

						{/* Image Side */}
						<div className="order-1 lg:order-2">
							<div className="lg:sticky lg:top-8 w-full">
								<div
									className="relative aspect-[4/5] rounded-xl overflow-hidden
                                  shadow-2xl shadow-black/20 
                                  transform transition-transform duration-500 hover:scale-[1.02]">
									<img
										src="/static/mma/tp-1.jpg"
										alt="MMA Defensive Movements"
										className="w-full h-full object-cover brightness-90
                                     transition-all duration-500 hover:brightness-100"
										loading="eager"
									/>
									<div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent" />

									{/* Image Overlay Content */}
									<div className="absolute bottom-6 left-6 right-6">
										<div className="bg-black/60 backdrop-blur-sm p-4 rounded-lg">
											<h4 className="text-primary-light font-semibold mb-2">
												Defensive Technique Practice
											</h4>
											<p className="text-sm text-gray-300">
												Master essential defensive movements to enhance your
												combat fitness
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Bottom Decoration */}
					<div className="mt-16 flex justify-center">
						<div className="h-1 w-24 bg-gradient-to-r from-primary-light/50 to-primary-dark/50 rounded-full" />
					</div>
				</div>
			</section>

			<section className="py-16">
				<div className="container mx-auto px-6">
					{/* Main Content */}
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-8 md:mb-16 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full" />
							<h2 className="text-4xl font-bold bg-clip-text text-lightShade bg-gradient-to-r from-primary to-primary-light">
								Important Considerations
							</h2>
							<div className="mt-6 flex flex-wrap gap-4">
								{["Proper Form", "Progression", "Partner Work"].map(
									(tip, index) => (
										<div
											key={index}
											className="flex items-center gap-2 px-4 py-2 bg-zinc-800/40 rounded-full border border-zinc-700/50">
											<FontAwesomeIcon
												icon={faCheck}
												className="text-primary-light text-sm"
											/>
											<span className="text-gray-300 text-sm font-medium">
												{tip}
											</span>
										</div>
									)
								)}
							</div>
						</div>

						{/* Timeline with Image Split */}
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8 lg:gap-12">
							{/* Left: Timeline Content */}
							<div className="relative order-2 lg:order-1">
								{/* Main vertical line */}
								<div
									className="absolute left-[28px] sm:left-[35px] top-0 bottom-0 w-[2px] md:w-[3px] 
                         bg-gradient-to-b from-zinc-800 via-primary/20 to-zinc-800"
								/>

								{[
									{
										name: "Slipping",
										moves:
											"Practicing slipping to the outside of straight punches (jabs and crosses)",
									},
									{
										name: "Rolling",
										moves:
											"Rolling under hooks, while level changing, can be combined with counter punches.",
									},
									{
										name: "Checking Leg Kicks",
										moves:
											"Leg kicks can be defended by lifting the leg and using the shin as a shield, pointing the knee toward the incoming kick.",
									},
								].map((combo, index) => (
									<div
										key={index}
										className="relative pl-[70px] sm:pl-[90px] pb-12 sm:pb-16 last:pb-0 group">
										{/* Number indicator */}
										<div className="absolute left-0 top-0">
											<div
												className="w-[56px] h-[56px] sm:w-[72px] sm:h-[72px] 
                              bg-zinc-900/80 backdrop-blur-sm
                              border-2 border-zinc-800 
                              group-hover:border-primary
                              transition-colors duration-300
                              flex items-center justify-center
                              shadow-lg shadow-black/20">
												<span
													className="text-2xl sm:text-3xl font-bold 
                                 bg-gradient-to-br from-primary-light to-primary 
                                 bg-clip-text text-transparent">
													{index + 1}
												</span>
											</div>
										</div>

										{/* Horizontal connector */}
										<div
											className="absolute left-[56px] sm:left-[72px] top-[27px] sm:top-[35px] 
                            w-[14px] sm:w-[18px] h-[2px] sm:h-[3px] bg-zinc-800
                            group-hover:bg-primary/50 
                            transition-colors duration-300"
										/>

										{/* Content Card */}
										<div
											className="bg-zinc-900/40 backdrop-blur-md 
                            border border-zinc-800/50 
                            group-hover:border-zinc-700
                            transition-colors duration-300
                            p-4 sm:p-6 md:p-8 rounded-lg
                            shadow-xl shadow-black/10">
											{/* Header */}
											<div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 mb-4">
												<h4 className="text-lg sm:text-xl md:text-2xl font-bold text-white">
													{combo.name}
												</h4>
											</div>

											{/* Content */}
											<p className="text-gray-300 text-sm sm:text-base md:text-lg leading-relaxed">
												{combo.moves}
											</p>
										</div>
									</div>
								))}
							</div>

							{/* Right: Image Section */}
							<div className="order-1 lg:order-2">
								<div className="lg:sticky lg:top-8 w-full">
									<div className="relative aspect-[4/5] rounded-lg sm:rounded-xl overflow-hidden">
										<img
											src="/static/mma/tp-4.png"
											alt="MMA Fighter demonstrating punching technique"
											className="object-cover w-full h-full brightness-90"
											loading="eager"
										/>
										{/* Image Overlay */}
										<div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent" />

										{/* Info overlay */}
										<div className="absolute bottom-4 sm:bottom-6 left-4 sm:left-6 right-4 sm:right-6">
											<div className="bg-black/60 backdrop-blur-sm px-3 py-2 sm:px-4 sm:py-3 rounded-lg">
												<h4 className="text-base sm:text-lg font-medium text-primary-light mb-0.5 sm:mb-1">
													Blue Level
												</h4>
												<p className="text-xs sm:text-sm text-gray-300">
													Basic Punching Combinations
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="py-24 bg-gradient-to-b from-zinc-900 to-black relative overflow-hidden">
				{/* Background Pattern */}
				<div className="absolute inset-0 bg-[url('/assets/grid-pattern.svg')] opacity-5"></div>

				<div className="container mx-auto px-6 relative z-10">
					{/* Section Header */}
					<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
						<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<div className="space-y-4">
							<h2 className="text-4xl md:text-5xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
								Integrating the Elements
							</h2>
							<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								Comprehensive Training Framework
							</h3>
							<p className="text-gray-300 text-lg leading-relaxed">
								A comprehensive high-impact fitness routine combines the
								following elements to create an effective and balanced training
								program:
							</p>
						</div>
					</div>

					{/* Elements Grid */}
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
						{[
							{
								title: "Warm-up",
								description:
									"Start with dynamic movements like the forward and backward bounce and practice your fighting stance, focusing on balance, posture, and light movement.",
								icon: faFire,
								gradient: "from-orange-500 to-red-600",
							},
							{
								title: "Stance and Movement Drill",
								description:
									"Practice moving in all directions (forward, backward, left, right), while maintaining a solid fighting stance, and then include faints and level changes.",
								icon: faPersonRunning,
								gradient: "from-blue-500 to-indigo-600",
							},
							{
								title: "Punching Combinations",
								description:
									"Work through combinations 1-4, focusing on form and rotation. You can practice these in the air or using light contact with pads or a heavy bag.",
								icon: faHandFist,
								gradient: "from-green-500 to-emerald-600",
							},
							{
								title: "Knee Strike Combinations",
								description:
									"Combine punching combinations with knee strikes, using skip steps where appropriate.",
								icon: faPersonPraying,
								gradient: "from-purple-500 to-violet-600",
							},
							{
								title: "Kick Combinations",
								description:
									"Include kicks to the legs and body using combinations 1-4.",
								icon: faKickstarter,
								gradient: "from-cyan-500 to-blue-600",
							},
						].map((element, index) => (
							<div
								key={index}
								className="group relative bg-zinc-900/40 backdrop-blur-md 
                             border border-zinc-800/50 
                             hover:border-primary/30
                             transition-all duration-300
                             p-6 rounded-xl
                             shadow-xl shadow-black/10
                             hover:shadow-2xl hover:shadow-primary/5
                             transform hover:-translate-y-1">
								{/* Gradient Background Effect */}
								<div
									className={`absolute inset-0 bg-gradient-to-br ${element.gradient} 
                                  opacity-0 group-hover:opacity-10 
                                  transition-opacity duration-500 rounded-xl`}
								/>

								{/* Content */}
								<div className="relative z-10">
									<div className="flex items-center gap-4 mb-6">
										<div
											className={`p-4 bg-gradient-to-br ${element.gradient} rounded-xl
                                          transform group-hover:scale-110 transition-transform duration-500
                                          shadow-lg`}>
											<FontAwesomeIcon
												icon={element.icon}
												className="text-2xl text-white"
											/>
										</div>
										<div className="flex-1 h-[2px] bg-gradient-to-r from-zinc-700 to-transparent" />
									</div>

									<h3
										className="text-xl font-bold 
                                     bg-gradient-to-r from-primary-light to-primary 
                                     bg-clip-text text-transparent mb-4">
										{element.title}
									</h3>

									<p
										className="text-gray-300 group-hover:text-gray-100 
                                    transition-colors duration-300 
                                    leading-relaxed">
										{element.description}
									</p>
								</div>
							</div>
						))}
					</div>

					{/* Bottom Decoration */}
					<div className="mt-16 flex justify-center">
						<div className="h-1 w-24 bg-gradient-to-r from-primary-light/50 to-primary-dark/50 rounded-full" />
					</div>
				</div>
			</section>

			<section className="py-24 bg-zinc-900">
				<div className="container mx-auto px-6">
					{/* Section Header */}
					<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
						<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<div className="space-y-4">
							<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
								Important Considerations
							</h2>
							<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								Key Factors for Success
							</h3>
							<p className="text-gray-300 text-lg leading-relaxed">
								To maximize the benefits of your training while minimizing the
								risk of injury, consider these essential aspects of the Blue
								Level program:
							</p>
						</div>
					</div>

					{/* Considerations Grid */}
					<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
						{[
							{
								number: "1",
								title: "Proper Form",
								description:
									"Focus on proper technique to avoid injury. The sources emphasize specific details like keeping elbows tucked, protecting the chin, and rotating hips and shoulders while punching.",
								icon: faShieldHalved,
							},
							{
								number: "2",
								title: "Progression",
								description:
									"Start slowly and gradually increase the intensity and duration of the workouts.",
								icon: faChartLine,
							},
							{
								number: "3",
								title: "Partner Work",
								description:
									"If possible, work with a partner to practice defensive movements and combinations. Use appropriate protective gear such as shin pads.",
								icon: faUserGroup,
							},
						].map((item, index) => (
							<div
								key={index}
								className="group relative bg-zinc-900/40 backdrop-blur-md 
                             border border-zinc-800/50 
                             hover:border-primary/30
                             transition-all duration-300
                             p-8 rounded-xl
                             shadow-xl shadow-black/10
                             hover:shadow-2xl hover:shadow-primary/5
                             transform hover:-translate-y-1">
								{/* Number Badge */}
								<div
									className="absolute -top-4 -left-4 w-12 h-12 rounded-full 
                                  bg-gradient-to-br from-primary-light to-primary
                                  flex items-center justify-center shadow-lg
                                  group-hover:scale-110 transition-transform duration-300">
									<span className="text-2xl font-bold text-white">
										{item.number}
									</span>
								</div>

								{/* Content */}
								<div className="pt-4">
									<div className="flex items-center gap-4 mb-6">
										<div
											className="w-12 h-12 rounded-xl bg-primary-dark/20 
                                          flex items-center justify-center
                                          group-hover:bg-primary-dark/30 
                                          transition-colors duration-300">
											<FontAwesomeIcon
												icon={item.icon}
												className="text-2xl text-primary-light"
											/>
										</div>
										<h3
											className="text-xl font-bold 
                                         bg-gradient-to-r from-primary-light to-primary 
                                         bg-clip-text text-transparent">
											{item.title}
										</h3>
									</div>

									<p
										className="text-gray-300 group-hover:text-gray-100 
                                    transition-colors duration-300 leading-relaxed">
										{item.description}
									</p>
								</div>
							</div>
						))}
					</div>

					{/* Bottom Text */}
					<div className="text-center max-w-3xl mx-auto">
						<p className="text-lg text-gray-300 leading-relaxed">
							These exercises, drawn from the Adrenaline Blue Level, provide a
							framework for high-impact Combat fitness programs using bodyweight
							exercises, calisthenics, and combat fitness elements.
						</p>
					</div>

					{/* Bottom Decoration */}
					<div className="mt-16 flex justify-center">
						<div className="h-1 w-24 bg-gradient-to-r from-primary-light/50 to-primary-dark/50 rounded-full" />
					</div>
				</div>
			</section>
			{/* Footer */}
			<footer className="bg-black py-12">
				<div className="container mx-auto px-6">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="text-gray-400 mb-6 md:mb-0">
							&copy; 2025 Adrenaline MMA. All rights reserved.
						</div>
						<div className="flex space-x-8">
							<Link
								to="https://www.facebook.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faFacebook} size="lg" />
							</Link>
							{/* <Link
								to="https://www.twitter.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faTwitter} size="lg" />
							</Link> */}
							<Link
								to="https://www.instagram.com/adrenalinemmahome/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faInstagram} size="lg" />
							</Link>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default AdrenalineMMATraining;
