import React, { useState, useEffect } from 'react';
import Scroll from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faDroplet, faDumbbell, faEye, faFilter, faFire, faGauge, faGenderless, faRefresh, faSearch, faTable } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useRecoilState } from "recoil";

import Layout from '../../Menu/Layout';
import FormErrorBox from "../../Reusable/FormErrorBox";
import { getExerciseListAPI } from '../../../API/Exercise';
import {
    topAlertMessageState,
    topAlertStatusState,
    currentUserState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import FormMultiSelectFieldForTags from "../../Reusable/FormMultiSelectFieldForTags";
import {
    PAGE_SIZE_OPTIONS,
    EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
    EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
    EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION
} from "../../../Constants/FieldOptions";
import MemberExerciseListDesktop from './ListDesktop';
import MemberExerciseListMobile from './ListMobile';


////
//// Custom Component
////

const ExerciseComponent = ({ description, name, thumbnailUrl, thumbnailObjectUrl, thumbnailType, gender, id }) => {
    return (
        <>
            {/* Desktop View */}
            <div className="hidden md:block">
                <MemberExerciseListDesktop 
                    name={name} 
                    description={description} 
                    thumbnailUrl={thumbnailUrl} 
                    thumbnailObjectUrl={thumbnailObjectUrl} 
                    thumbnailType={thumbnailType} 
                    gender={gender} 
                    id={id} 
                />
            </div>

            {/* Mobile View */}
            <div className="md:hidden w-full">
                <MemberExerciseListMobile 
                    name={name} 
                    description={description} 
                    thumbnailUrl={thumbnailUrl} 
                    thumbnailObjectUrl={thumbnailObjectUrl} 
                    thumbnailType={thumbnailType} 
                    gender={gender} 
                    id={id} 
                />
            </div>
        </>
    );
}

const Modal = ({ isActive, title, children, footer, onClose }) => (
    <div className={`fixed inset-0 z-50 ${isActive ? 'block' : 'hidden'}`}>
        <div className="absolute inset-0 bg-darkShade/50 backdrop-blur-sm transition-opacity duration-300" onClick={onClose}></div>
        <div className="relative max-w-lg mx-auto mt-24 bg-white rounded-xl shadow-2xl transform transition-all duration-300 scale-100">
            <header className="flex justify-between items-center p-6 border-b">
                <h3 className="text-xl font-bold text-darkShade">{title}</h3>
                <button 
                    onClick={onClose} 
                    className="text-gray-500 hover:text-darkShade transition-colors duration-200 rounded-full p-2 hover:bg-gray-100"
                >
                    <span className="sr-only">Close</span>
                    ×
                </button>
            </header>
            <div className="p-6">
                {children}
            </div>
            {footer && <footer className="p-6 border-t bg-gray-50">{footer}</footer>}
        </div>
    </div>
);

const MemberExerciseList = () => {

    ////
    //// Global state.
    ////

    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser] = useRecoilState(currentUserState);

    ////
    //// Component states.
    ////

    const [errors, setErrors] = useState({});
    const [listData, setListData] = useState("");
    const [selectedExerciseForDeletion, setSelectedExerciseForDeletion] = useState("");
    const [isFetching, setFetching] = useState(false);
    const [pageSize, setPageSize] = useState(10); // Pagination
    const [previousCursors, setPreviousCursors] = useState([]); // Pagination
    const [nextCursor, setNextCursor] = useState(""); // Pagination
    const [currentCursor, setCurrentCursor] = useState(""); // Pagination
    const [showFilter, setShowFilter] = useState(false); // Filtering + Searching
    const [sortField, setSortField] = useState("created"); // Sorting
    const [temporarySearchText, setTemporarySearchText] = useState(""); // Searching - The search field value as your writes their query.
    const [actualSearchText, setActualSearchText] = useState(""); // Searching - The actual search query value to submit to the API.
    const [category, setCategory] = useState("");
    const [movementType, setMovementType] = useState("");
    const [status, setStatus] = useState("");
    const [gender, setGender] = useState("");
    const [videoType, setVideoType] = useState("");
    const [tags, setTags] = useState([]);

    ////
    //// BREADCRUMB
    ////
    const breadcrumbItems = [
        { label: 'Dashboard', link: '/dashboard', icon: faGauge },
        { label: 'Exercises', icon: faDumbbell }
    ]

    ////
    //// API.
    ////

    const onExerciseListSuccess = (response) => {
        console.log("onExerciseListSuccess: Starting...");
        if (response.results !== null) {
            setListData(response);
            if (response.hasNextPage) {
                setNextCursor(response.nextCursor); // For pagination purposes.
            }
        } else {
            setListData([]);
            setNextCursor("");
        }
    }

    const onExerciseListError = (apiErr) => {
        console.log("onExerciseListError: Starting...");
        setErrors(apiErr);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    const onExerciseListDone = () => {
        console.log("onExerciseListDone: Starting...");
        setFetching(false);
    }


    ////
    //// Event handling.
    ////

    // Note: currentCursor, pageSize, actualSearchText, category, movementType, status, gender, videoType, tags
    const fetchList = (cur, limit, keywords, cat, mt, st, g, vt, t) => {
        setFetching(true);
        setErrors({});

        let params = new Map();
        params.set("page_size", limit); // Pagination
        params.set("sort_field", "created"); // Sorting
        params.set("sort_order", -1)         // Sorting - descending, meaning most recent start date to oldest start date.

        if (cur !== "") {
            // Pagination
            params.set("cursor", cur);
        }

        // Filtering
        if (keywords !== undefined && keywords !== null && keywords !== "") {
            // Searhcing
            params.set("search", keywords);
        }
        if (cat !== undefined && cat !== null && cat !== "") {
            params.set("category", cat);
        }
        if (mt !== undefined && mt !== null && mt !== "") {
            params.set("movement_type", mt);
        }
        if (st !== undefined && st !== null && st !== "") {
            params.set("status", st);
        }
        if (g !== undefined && g !== null && g !== "") {
            params.set("gender", g);
        }
        if (vt !== undefined && vt !== null && vt !== "") {
            params.set("video_type", vt);
        }
        if (t !== undefined && t !== null && t !== "") {
            params.set("tags", t);
        }

        getExerciseListAPI(
            params,
            onExerciseListSuccess,
            onExerciseListError,
            onExerciseListDone
        );
    };

    const onNextClicked = (e) => {
        console.log("onNextClicked");
        let arr = [...previousCursors];
        arr.push(currentCursor);
        setPreviousCursors(arr);
        setCurrentCursor(nextCursor);
    };

    const onPreviousClicked = (e) => {
        console.log("onPreviousClicked");
        let arr = [...previousCursors];
        const previousCursor = arr.pop();
        setPreviousCursors(arr);
        setCurrentCursor(previousCursor);
    };

    const onSearchButtonClick = (e) => {
        // Searching
        console.log("Search button clicked...");
        setActualSearchText(temporarySearchText);
    };

    ////
    //// Misc.
    ////

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            Scroll.animateScroll.scrollToTop();
            fetchList(currentCursor, pageSize, actualSearchText, category, movementType, status, gender, videoType, tags);
        }

        return () => {
            mounted = false;
        };
    }, [currentCursor, pageSize, actualSearchText, category, movementType, status, gender, videoType, tags]);

    ////
    //// Component rendering.
    ////

    return (
        <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
            <div className="p-8 bg-white rounded-xl shadow-sm">
                {/* Header Section */}
                <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                    <div className="mb-4 md:mb-0">
                        <h1 className="text-3xl font-bold flex items-center text-darkShade group">
                            <FontAwesomeIcon 
                                className="mr-3 text-main group-hover:rotate-12 transition-transform duration-300" 
                                icon={faDumbbell} 
                            />
                            Exercises
                        </h1>
                    </div>
                    <div className="flex gap-3">
                        <button 
                            onClick={() => fetchList(currentCursor, pageSize, actualSearchText, category, movementType, status, gender, videoType, tags)} 
                            className="flex items-center px-4 py-2.5 text-sm bg-blueShade1 text-white rounded-lg hover:bg-blueShade2 transition-all duration-300 shadow-sm hover:shadow-md"
                        >
                            <FontAwesomeIcon className="mr-2" icon={faRefresh} />
                            Refresh
                        </button>
                        <button 
                            onClick={(e) => setShowFilter(!showFilter)} 
                            className="flex items-center px-4 py-2.5 text-sm bg-main text-white rounded-lg hover:bg-darkOrangeShade transition-all duration-300 shadow-sm hover:shadow-md"
                        >
                            <FontAwesomeIcon className="mr-2" icon={faFilter} />
                            Filter
                        </button>
                    </div>
                </div>

                {/* Filter Section */}
                {showFilter && (
                    <div className="bg-lightGrayShade rounded-xl p-6 mb-8 shadow-inner">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
                        <div>
                                <FormInputFieldWithButton
                                    label="Search"
                                    name="temporarySearchText"
                                    type="text"
                                    placeholder="Search by name"
                                    value={temporarySearchText}
                                    onChange={(e) => setTemporarySearchText(e.target.value)}
                                    isRequired={true}
                                    maxWidth="100%"
                                    buttonLabel={<FontAwesomeIcon icon={faSearch} />}
                                    onButtonClick={onSearchButtonClick}
                                    className="w-full"
                                />
                            </div>
                            <div>
                                <FormSelectField
                                    label="Category"
                                    name="category"
                                    placeholder="Pick"
                                    selectedValue={category}
                                    errorText={errors && errors.category}
                                    onChange={(e) => setCategory(parseInt(e.target.value))}
                                    options={EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION}
                                    className="w-full"
                                />
                            </div>
                            <div>
                                <FormSelectField
                                    label="Movement Type"
                                    name="movementType"
                                    placeholder="Pick"
                                    selectedValue={movementType}
                                    errorText={errors && errors.movementType}
                                    onChange={(e) => setMovementType(parseInt(e.target.value))}
                                    options={EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION}
                                    className="w-full"
                                />
                            </div>
                            <div>
                                <FormSelectField
                                    label="Gender"
                                    name="gender"
                                    placeholder="Pick"
                                    selectedValue={gender}
                                    errorText={errors && errors.gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    options={EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION}
                                    className="w-full"
                                />
                            </div>
                            <div>
                                <FormMultiSelectFieldForTags
                                    label="Tags"
                                    name="tags"
                                    placeholder="Pick tags"
                                    tags={tags}
                                    setTags={setTags}
                                    errorText={errors && errors.tags}
                                    isRequired={true}
                                    className="w-full"
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Content Section */}
                {isFetching ? (
                    <div className="flex items-center justify-center p-12">
                        <PageLoadingContent displayMessage={"Please wait..."} />
                    </div>
                ) : (
                    <>
                        <FormErrorBox errors={errors} />
                        {listData && listData.results && 
                         (listData.results.length > 0 || previousCursors.length > 0) ? (
                            <div>
                                {/* Exercise List */}
                                <div className="space-y-6">
                                    {listData.results.map((exercise) => (
                                        <ExerciseComponent
                                            key={exercise.id}
                                            {...exercise}
                                        />
                                    ))}
                                </div>

                                {/* Pagination */}
                                <div className="flex flex-col md:flex-row justify-between items-center mt-8 pt-6">
                                    <div className="w-full md:w-auto mb-4 md:mb-0">
                                        <select
                                            className="w-full md:w-auto px-4 py-2.5 border border-gray-300 rounded-lg text-gray-600 focus:outline-none focus:border-main focus:ring-2 focus:ring-main/20 transition-all duration-200"
                                            name="pageSize"
                                            onChange={(e) => setPageSize(parseInt(e.target.value))}
                                        >
                                            {PAGE_SIZE_OPTIONS.map((option, i) => (
                                                <option
                                                    key={i}
                                                    selected={pageSize === option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex gap-3">
                                        {previousCursors.length > 0 && (
                                            <button
                                                className="px-6 py-2.5 bg-white border border-gray-300 rounded-lg hover:bg-lightGrayShade transition-all duration-200 hover:shadow-sm"
                                                onClick={onPreviousClicked}
                                            >
                                                Previous
                                            </button>
                                        )}
                                        {listData.hasNextPage && (
                                            <button 
                                                className="px-6 py-2.5 bg-white border border-gray-300 rounded-lg hover:bg-lightGrayShade transition-all duration-200 hover:shadow-sm"
                                                onClick={onNextClicked}
                                            >
                                                Next
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center py-20 bg-lightGrayShade rounded-xl">
                                <div className="bg-white p-6 rounded-full mb-4 shadow-sm">
                                    <FontAwesomeIcon 
                                        className="text-4xl text-gray-400" 
                                        icon={faTable} 
                                    />
                                </div>
                                <h2 className="text-2xl font-bold mb-2 text-darkShade">
                                    No Exercises Found
                                </h2>
                                <p className="text-gray-600 text-center max-w-md">
                                    No exercises found at the moment. Please check back later or try adjusting your filters.
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Layout>
    );
}

export default MemberExerciseList;
