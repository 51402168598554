import React, { useState, useEffect } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import Scroll from "react-scroll";

import FormErrorBox from "../../Reusable/FormErrorBox";
import { postVertifyOTP } from "../../../API/gateway";
import { currentOTPResponseState, currentUserState } from "../../../AppState";
import {
  ROOT_ROLE_ID,
  ADMIN_ROLE_ID,
  TRAINER_ROLE_ID,
  MEMBER_ROLE_ID,
} from "../../../Constants/App";

function TwoFactorAuthenticationWizardStep3() {
  const [searchParams] = useSearchParams();
  const paramToken = searchParams.get("token");
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [otpResponse] = useRecoilState(currentOTPResponseState);
  const [errors, setErrors] = useState({});
  const [forceURL, setForceURL] = useState("");
  const [verificationToken, setVerificationToken] = useState("");
  const [submittedParamToken, setSubmittedParamToken] = useState(false);

  function onVerifyOPTSuccess(response) {
    if (response?.user) {
      setCurrentUser(response.user);
      switch (response.user.role) {
        case ROOT_ROLE_ID: 
          setForceURL("/root/tenants"); 
          break;
        case ADMIN_ROLE_ID: 
          setForceURL("/admin/dashboard"); 
          break;
        case TRAINER_ROLE_ID: 
          setForceURL("/admin/dashboard"); 
          break;
        case MEMBER_ROLE_ID: 
          setForceURL(response?.user?.onboardingCompleted ? "/dashboard" : "/onboarding");
          break;
        default: 
          setForceURL("/501"); 
          break;
      }
    }
  }

  function onVerifyOPTError(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onButtonClick() {
    const cleanedToken = verificationToken.replace(/\s/g, "");
    postVertifyOTP(
      { verification_token: cleanedToken },
      onVerifyOPTSuccess,
      onVerifyOPTError,
      () => console.log("onVerifyOPTDone: Starting...")
    );
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      window.scrollTo(0, 0);
      if (!submittedParamToken && paramToken) {
        postVertifyOTP(
          { verification_token: paramToken },
          onVerifyOPTSuccess,
          onVerifyOPTError,
          () => console.log("onVerifyOPTDone: Starting...")
        );
        setSubmittedParamToken(true);
        setVerificationToken(paramToken);
      }
    }
    return () => (mounted = false);
  }, [paramToken, submittedParamToken]);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-zinc-900 to-backgroundDark py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto">
        <div className="bg-zinc-800/30 backdrop-blur-sm rounded-2xl p-8 shadow-xl animate-fade-in">
          {/* Progress Bar */}
          <div className="bg-zinc-900/50 rounded-xl p-6 mb-8">
            <p className="text-gray-300 text-lg mb-2">Step 3 of 3</p>
            <div className="w-full bg-gray-700 rounded-full h-2">
              <div 
                className="bg-primary w-3/3 h-2 rounded-full animate-pulse-glow"
                style={{ transition: 'width 0.5s ease-in-out' }}
              ></div>
            </div>
          </div>

          {/* Header */}
          <h1 className="text-3xl font-bold text-center text-lightShade mb-8 font-primary">
            Setup Two-Factor Authentication
          </h1>
          
          <FormErrorBox errors={errors} />

          {/* Description */}
          <p className="text-gray-400 mb-8">
            Open the two-step verification app on your mobile device to get your verification code.
          </p>

          {/* Verification Input */}
          <div className="space-y-4 mb-8">
            <label className="block text-gray-300 text-sm font-medium">
              Enter your Verification Token
              <span className="text-primary-light">*</span>
            </label>
            <input
              type="text"
              value={verificationToken}
              onChange={(e) => setVerificationToken(e.target.value)}
              className="w-full max-w-[380px] px-4 py-3 bg-zinc-900/50 border border-gray-700 rounded-xl 
                         text-gray-200 placeholder-gray-500 focus:ring-2 focus:ring-primary-light 
                         focus:border-transparent transition-all duration-300"
              placeholder="See your authenticator app"
            />
            {errors?.verificationToken && (
              <p className="text-red-500 text-sm mt-1">{errors.verificationToken}</p>
            )}
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-between mt-8">
            <Link
              to="/login/2fa/step-2"
              className="inline-flex items-center px-6 py-3 rounded-xl bg-zinc-700 
                       text-gray-200 hover:bg-zinc-600 transition-colors"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back
            </Link>
            <button
              onClick={onButtonClick}
              className="inline-flex items-center px-6 py-3 rounded-xl bg-greenShade1 
                       text-lightShade hover:bg-greenShade2 transition-colors 
                       animate-pulse-glow"
            >
              <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
              Submit and Verify
            </button>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center mt-8 text-gray-400">
          <p>© 2024 Adrenaline MMA Training & Fitness Center Team</p>
        </div>
      </div>
    </div>
  );
}

export default TwoFactorAuthenticationWizardStep3;