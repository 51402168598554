import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFire, faDroplet, faGenderless, faClock } from '@fortawesome/free-solid-svg-icons';
import {
    EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
    EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL
} from "../../../Constants/App";

function MemberExerciseListDesktop({ 
  description,
  name,
  thumbnailUrl,
  thumbnailObjectUrl,
  thumbnailType,
  gender,
  id,
  thumbnailObjectExpiry,
  duration = "10-15 min",
  difficulty = "Intermediate"
}) {
  const [imageError, setImageError] = useState(false);
  const fallbackImageUrl = 'https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';

  const getImageUrl = () => {
    if (imageError) return fallbackImageUrl;
    if (thumbnailType === EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE && thumbnailObjectUrl) return thumbnailObjectUrl;
    if (thumbnailType === EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL && thumbnailUrl) return thumbnailUrl;
    return fallbackImageUrl;
  };

  return (
    <div className="w-full duration-DEFAULT mb-6">
      <div className="bg-lightShade rounded-lg shadow-lg hover:shadow-xl overflow-hidden">
        <div className="flex p-6 gap-8">
          {/* Image Section */}
          <div className="w-80 h-80 flex-shrink-0 relative group overflow-hidden rounded-sm">
            <img
              src={getImageUrl()}
              alt={name}
              className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
              onError={() => !imageError && setImageError(true)}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-darkShade/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-DEFAULT" />
            
            {/* Difficulty Badge */}
            <div className="absolute top-4 left-4 bg-lightShade/90 backdrop-blur-sm px-4 py-2 rounded-full">
              <span className="text-sm font-semibold text-darkShade">{difficulty}</span>
            </div>
          </div>

          {/* Content Section */}
          <div className="flex-1 flex flex-col justify-between">
            <div>
              <div className="flex items-center gap-4 mb-3">
                <span className="flex items-center text-sm text-gray-600">
                  <FontAwesomeIcon icon={faClock} className="mr-2" />
                  {duration}
                </span>
              </div>
              
              <h3 className="text-3xl font-bold text-textDark mb-4 leading-tight font-primary">
                {name}
              </h3>
              
              <p className="text-darkShade leading-relaxed text-lg mb-6 font-secondary">
                {description || 'No description available'}
              </p>

              {/* Tags Section */}
              <div className="flex flex-wrap gap-3">
                <span className="flex items-center bg-gradient-to-r from-burntOrange/20 to-main/20 text-main px-4 py-2 rounded-sm">
                  <FontAwesomeIcon icon={faFire} className="mr-2 text-main" />
                  <span className="font-medium text-sm">Ground-Based</span>
                </span>

                <span className="flex items-center bg-gradient-to-r from-softBlue/20 to-blueShade1/20 text-blueShade1 px-4 py-2 rounded-sm">
                  <FontAwesomeIcon icon={faDroplet} className="mr-2 text-blueShade1" />
                  <span className="font-medium text-sm">Warmups</span>
                </span>

                {gender && (
                  <span className="flex items-center bg-gradient-to-r from-accent1/20 to-accent1/30 text-accent1 px-4 py-2 rounded-sm">
                    <FontAwesomeIcon icon={faGenderless} className="mr-2" />
                    <span className="font-medium text-sm">{gender}</span>
                  </span>
                )}
              </div>
            </div>

            {/* Action Button and Progress */}
            <div className="mt-8 flex items-center justify-between">
              <Link
                to={`/exercise/${id}`}
                className="group inline-flex items-center px-6 py-3 bg-main hover:bg-darkOrangeShade text-lightShade rounded-sm 
                         transition-all duration-DEFAULT shadow-md hover:shadow-lg"
              >
                <FontAwesomeIcon 
                  icon={faEye} 
                  className="mr-2 group-hover:scale-110 transition-transform duration-DEFAULT" 
                />
                <span className="font-medium">View Exercise</span>
              </Link>
              
              {/* Progress Indicator */}
              <div className="flex items-center gap-2">
                <div className="w-32 h-2 bg-lightGrayShade rounded-full overflow-hidden">
                  <div className="w-3/4 h-full bg-gradient-to-r from-main to-darkOrangeShade rounded-full animate-gradient-flow" />
                </div>
                <span className="text-sm text-gray-600 font-medium">75% Complete</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberExerciseListDesktop;