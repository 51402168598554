import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faFacebook,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
	faSignInAlt,
	faUserPlus,
	faDumbbell,
	faHeartbeat,
	faChartLine,
	faDollarSign,
	faListCheck,
	faFire,
	faBolt,
	faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

function IndexStaticPage() {
	return (
		<div className="min-h-screen bg-black text-white">
			{/* Navigation Bar */}
			<nav className="fixed top-0 w-full bg-black/80 backdrop-blur-md z-50">
				<div className="container mx-auto px-6 py-4">
					<div className="flex justify-between items-center">
						<div className="flex items-center space-x-16">
							<img src="logo.svg" alt="Logo" className="h-12 w-auto" />
						</div>
						<div className="flex items-center space-x-6">
							<Link
								to="/login"
								className="text-gray-300 hover:text-white transition-colors duration-300 flex items-center space-x-2 group">
								<FontAwesomeIcon
									icon={faSignInAlt}
									className="group-hover:rotate-12 transition-transform duration-300"
								/>
								<span>Login</span>
							</Link>
							<Link
								to="/register"
								className="bg-primary hover:bg-primary/30 px-6 py-2 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center space-x-2 shadow-lg shadow-primary/20">
								<FontAwesomeIcon
									icon={faUserPlus}
									className="group-hover:scale-110 transition-transform duration-300"
								/>
								<span>Get Started</span>
							</Link>
						</div>
					</div>
				</div>
			</nav>

			{/* Hero Section */}
			<section className="relative h-screen pt-32 pb-20 md:pt-40 md:pb-32">
				<div className="absolute inset-0 bg-[url('/static/mma/index.jpg')] bg-cover bg-center">
					<div className="absolute inset-0 bg-gradient-to-r from-black via-black/70 to-transparent"></div>
				</div>
				<div className="container mx-auto px-6 relative">
					<div className="max-w-4xl space-y-8">
						<div className="space-y-4">
							<h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-bold tracking-tight">
								<span className="text-white animate-gradient">
									Adrenaline Combat Fitness
								</span>
							</h1>
							<h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">
								Unleash Your
								<span className="text-primary relative inline-block animate-pulse-subtle">
									&nbsp;Inner Fighter
								</span>
							</h2>
						</div>

						<p className="text-base sm:text-lg md:text-xl text-gray-300 max-w-3xl leading-relaxed">
							Join a community of fitness warriors and achieve your peak
							performance.
						</p>

						<div className="flex flex-col sm:flex-row gap-6 pt-4">
							<Link
								to="/register"
								className="relative inline-flex items-center px-10 py-5 overflow-hidden text-lightShade bg-primary rounded-full group hover:scale-105 transition-all duration-300 shadow-lg shadow-primary/20">
								<FontAwesomeIcon
									icon={faFire}
									className="mr-3 animate-sparkle"
								/>
								<span className="font-semibold">Start Training Now</span>
								<div className="absolute inset-0 bg-white/20 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300"></div>
							</Link>
							<Link
								to="/programs"
								className="bg-transparent border-2 border-white/20 hover:border-primary text-white px-8 py-4 rounded-full text-lg font-semibold transition-all duration-300 flex items-center justify-center hover:bg-white/5 hover:scale-105">
								<FontAwesomeIcon icon={faBolt} className="mr-3 text-primary" />
								Explore Programs
							</Link>
						</div>
					</div>
				</div>
			</section>

			{/* Features Grid */}
			<section className="py-20 bg-zinc-900">
				<div className="container mx-auto px-6">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						{/* Feature Cards */}
						<div className="group p-8 rounded-2xl bg-zinc-800/50 hover:bg-zinc-800 transition-all duration-300">
							<div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
								<FontAwesomeIcon
									icon={faDumbbell}
									className="text-primary text-2xl"
								/>
							</div>
							<h3 className="text-xl font-bold mb-4">
								The Combat Fitness Framework
							</h3>
							<p className="text-gray-400">
								The Adrenaline high-intensity fitness training framework that
								focuses on body weight exercises, calisthenics, shadow boxing,
								core work and combat fitness tactics.
							</p>
							<div className="pt-4">
								<Link
									to="/adrenaline-combat-framework"
									className="relative inline-flex items-center px-7 py-2 overflow-hidden text-lightShade bg-primary hover:bg-primary/30 rounded-full group duration-300 shadow-lg">
									<FontAwesomeIcon
										icon={faArrowRight}
										className="mr-2 animate-pulse"
									/>
									Learn More
								</Link>
							</div>
						</div>

						<div className="group p-8 rounded-2xl bg-zinc-800/50 hover:bg-zinc-800 transition-all duration-300">
							<div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
								<FontAwesomeIcon
									icon={faHeartbeat}
									className="text-primary text-2xl"
								/>
							</div>
							<h3 className="text-xl font-bold mb-4">The 12 Week Program</h3>
							<p className="text-gray-400">
								Elevate your fitness level with our intensive 12 week Combat
								Fitness program based on the Adrenaline Blue Best MMA system - 3
								days a week for 45-60 minutes per session.
							</p>
							<div className="pt-4">
								<Link
									to="/the-12-week-program"
									className="relative inline-flex items-center px-7 py-2 overflow-hidden text-lightShade bg-primary hover:bg-primary/30 rounded-full group duration-300 shadow-lg">
									<FontAwesomeIcon
										icon={faArrowRight}
										className="mr-2 animate-pulse"
									/>
									Learn More
								</Link>
							</div>
						</div>

						<div className="group p-8 rounded-2xl bg-zinc-800/50 hover:bg-zinc-800 transition-all duration-300">
							<div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
								<FontAwesomeIcon
									icon={faChartLine}
									className="text-primary text-2xl"
								/>
							</div>
							<h3 className="text-xl font-bold mb-4">
								The Science of Adrenaline Combat Fitness
							</h3>
							<p className="text-gray-400">
								Adrenaline Combat Fitness integrates cutting-edge fitness
								science, drawn from peer reviewed journals, into its training
								regime to provide unique, high intensity fitness training.
							</p>
							<div className="pt-4">
								<Link
									to="/science-of-adrenaline-combat-fitness"
									className="relative inline-flex items-center px-7 py-2 overflow-hidden text-lightShade bg-primary hover:bg-primary/30 rounded-full group duration-300 shadow-lg">
									<FontAwesomeIcon
										icon={faArrowRight}
										className="mr-2 animate-pulse"
									/>
									Learn More
								</Link>
							</div>
						</div>

						<div className="group p-8 rounded-2xl bg-zinc-800/50 hover:bg-zinc-800 transition-all duration-300">
							<div className="w-12 h-12 bg-primary/10 rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
								<FontAwesomeIcon
									icon={faChartLine}
									className="text-primary text-2xl"
								/>
							</div>
							<h3 className="text-xl font-bold mb-4">
								The Benefits of Adrenaline Blue Level Badge
							</h3>
							<p className="text-gray-400 mb-8">
								Provides a comprehensive analysis of Adrenaline MMA's Blue Level
								badge system, a bodyweight and calisthenics program that
								incorporates MMA techniques into a high intensity Combat Fitness
								program.
							</p>
							<div className="pt-4">
								<Link
									to="/blue-level-badge-benefits"
									className="relative inline-flex items-center px-7 py-2 overflow-hidden text-lightShade bg-primary hover:bg-primary/30 rounded-full group duration-300 shadow-lg">
									<FontAwesomeIcon
										icon={faArrowRight}
										className="mr-2 animate-pulse"
									/>
									Learn More
								</Link>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Footer */}
			<footer className="bg-black py-12">
				<div className="container mx-auto px-6">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="text-gray-400 mb-6 md:mb-0">
							&copy; 2025 Adrenaline MMA. All rights reserved.
						</div>
						<div className="flex space-x-8">
							<Link
								to="https://www.facebook.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faFacebook} size="lg" />
							</Link>
							{/* <Link
								to="https://www.twitter.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faTwitter} size="lg" />
							</Link> */}
							<Link
								to="https://www.instagram.com/adrenalinemmahome/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faInstagram} size="lg" />
							</Link>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default IndexStaticPage;
