import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowRight,
	faEnvelope,
	faLock,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import FormInputField from "../Reusable/FormInputField"; // Adjust the path as needed
import FormCheckboxField from "../Reusable/FormCheckboxField"; // Adjust the path as needed
import FormErrorBox from "../Reusable/FormErrorBox";
import Button from "../Reusable/Button";
import FormInputPasswordField from "../Reusable/FormInputPasswordField";

const LoginDesktop = ({
	errors,
	isUnauthorized,
	validation,
	email,
	onEmailChange,
	password,
	onPasswordChange,
	onButtonClick,
	rememberMe,
	setRememberMe,
}) => {
	return (
		<div className="max-h-min flex">
			{/* Left Side - Welcome Section */}
			<div className="w-1/2 bg-darkShade flex flex-col rounded-l-lg justify-center items-center text-white p-16">
				<div className="flex flex-col items-center">
					<img
						src="/static/logo.png"
						style={{width: "120px", borderRadius: "50%"}}
						alt="Logo"
					/>
					<h1 className="text-4xl font-bold mb-4">Welcome Back!</h1>
					<p className="text-lg text-gray-400 text-center">
						Hello again, login to explore!
					</p>
				</div>
			</div>

			{/* Divider */}
			<div className="w-[1px] bg-gray-200"></div>

			{/* Right Side - Form Section */}
			<div className="w-1/2 bg-lightShade flex flex-col justify-center rounded-r-lg items-center p-16">
				<div className="w-full max-w-md">
					<form className="space-y-6">
						{/* Unauthorized Message */}
						{isUnauthorized === "true" && (
							<div className="mb-6 p-4 bg-red-50 rounded-xl">
								<div className="flex items-center text-red-600">
									<FontAwesomeIcon
										icon={faTriangleExclamation}
										className="mr-2"
									/>
									<span>Your session has expired. Please login again.</span>
								</div>
							</div>
						)}
						<FormErrorBox errors={errors} />

						{/* Email Input */}
						<FormInputField
							id="desktopEmail"
							label="Email Address"
							name="email"
							type="email"
							placeholder="Enter your email"
							value={email}
							onChange={onEmailChange}
							error={errors?.email}
							validation={validation?.email}
							icon={faEnvelope}
						/>

						{/* Password Input */}
						<FormInputPasswordField
							id="desktopPassword"
							label="Password"
							name="password"
							placeholder="Enter your password"
							value={password}
							onChange={onPasswordChange}
							errorText={errors?.password}
							validation={validation?.password}
							icon={faLock}
						/>

						<div className="flex justify-between">
							{/* Remember Me Checkbox */}
							<FormCheckboxField
								id="desktopRememberMe"
								label="Remember me"
								name="rememberMe"
								checked={rememberMe}
								onChange={() => setRememberMe(!rememberMe)}
							/>
							{/* Forgot Password Link */}
							<Link
								to="/forgot-password"
								className="text-sm text-main hover:underline">
								Forgot Password?
							</Link>
						</div>

						{/* Login Button */}
						<Button
							id="desktopLoginButton"
							text="Login"
							icon={faArrowRight}
							onClick={onButtonClick}
							fullWidth
						/>

						{/* Create Account Link */}
						<div className="text-center mt-4">
							<p className="text-gray-600">
								Don't have an account yet?{" "}
								<Link
									to="/register"
									className="text-main font-semibold hover:underline">
									Create an Account
								</Link>
							</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LoginDesktop;
