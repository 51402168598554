import React, {useEffect} from "react";
import Scroll from "react-scroll";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faChevronDown,
	faCheckCircle,
	faChartLine,
	faSignInAlt,
	faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
	faFacebook,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const benefitsData = [
	{
		number: "1",
		name: "Physical Benefits",
		description:
			"Cardiovascular training, strength training, flexibility and mobility training, and breathing techniques",
	},
	{
		number: "2",
		name: "Mental Benefits",
		description:
			"Stress reduction, emotional resilience, enhanced mental focus, and increased self-confidence",
	},
	{
		number: "3",
		name: "Technical Benefits",
		description:
			"Technical skill development in striking, grappling, and wrestling techniques",
	},
	{
		number: "4",
		name: "Social Benefits",
		description:
			"Community support, training partnerships, networking opportunities, and shared fitness goals within the MMA community",
	},
];

const evidenceData = [
	{
		title: "Cardiovascular fitness",
		description:
			"Jump rope, shadow boxing, and high-intensity circuits improve endurance and stamina.",
	},
	{
		title: "Strength and power",
		description:
			"Push-ups, squats, and dumbbell exercises build strength and explosive power.",
	},
	{
		title: "Coordination and balance",
		description:
			"Shadow boxing, striking drills, and grappling techniques enhance coordination and balance.",
	},
	{
		title: "Flexibility and mobility",
		description:
			"Dynamic stretching and MMA techniques improve flexibility and range of motion.",
	},
];

const whyItWorksData = [
	{
		title: "Comprehensive Approach",
		content:
			"MMA-style fitness programs, like Adrenaline MMA's Blue Level badge system, offer a comprehensive and effective approach to fitness training. By combining bodyweight exercises, calisthenics, and MMA techniques, these programs provide a challenging and engaging workout that can improve cardiovascular health, strength, flexibility, and mental focus.",
	},
	{
		title: "Adaptable for All Levels",
		content:
			"The benefits of MMA-style fitness extend to individuals with diverse fitness levels and goals. Beginners can start with basic exercises and techniques, gradually progressing to more challenging variations as they improve. Experienced athletes can use MMA-style fitness to supplement their existing training regimens or to focus on specific areas for improvement.",
	},
	{
		title: "Holistic Benefits",
		content:
			"By incorporating MMA-style fitness into a broader fitness regimen, individuals can experience a wide range of physical, mental, and technical benefits. This type of training can help individuals achieve their fitness goals while developing valuable skills and improving their overall well-being.",
	},
];

const BlueLevelBadgeBenefits = () => {
	useEffect(() => {
		Scroll.animateScroll.scrollToTop();
	}, []);

	return (
		<div className="relative min-h-screen">
			{/* Navigation Bar */}
			<nav className="fixed top-0 w-full bg-black/80 backdrop-blur-md z-50">
				<div className="container mx-auto px-6 py-4">
					<div className="flex justify-between items-center">
						<div className="flex items-center space-x-16">
							<img src="logo.svg" alt="Logo" className="h-12 w-auto" />
						</div>
						<div className="flex items-center space-x-6">
							<Link
								to="/login"
								className="text-gray-300 hover:text-white transition-colors duration-300 flex items-center space-x-2 group">
								<FontAwesomeIcon
									icon={faSignInAlt}
									className="group-hover:rotate-12 transition-transform duration-300"
								/>
								<span>Login</span>
							</Link>
							<Link
								to="/register"
								className="bg-primary hover:bg-primary/30 px-6 py-2 rounded-full transition-all duration-300 transform hover:scale-105 flex items-center space-x-2 shadow-lg shadow-primary/20">
								<FontAwesomeIcon
									icon={faUserPlus}
									className="group-hover:scale-110 transition-transform duration-300"
								/>
								<span>Get Started</span>
							</Link>
						</div>
					</div>
				</div>
			</nav>
			{/* Hero Section */}
			<section className="relative min-h-screen flex items-center pt-28 md:pt-32">
				{/* Background Image */}
				<div className="absolute inset-0 z-0">
					<img
						src="/static/mma/index.jpg"
						alt="MMA Training"
						className="w-full h-full object-cover"
					/>
					<div className="absolute inset-0 bg-gradient-to-r from-zinc-900 via-zinc-900/85 to-zinc-900/50" />
				</div>

				{/* Content */}
				<div className="container mx-auto px-4 sm:px-6 relative z-10">
					<div className="max-w-4xl space-y-8 md:space-y-12">
						<div className="space-y-4">
							<h1
								className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 
                      font-bold tracking-tight leading-[1.1] 
                      bg-gradient-to-r from-primary-light to-primary-dark 
                      bg-clip-text text-white animate-fade-in">
								Combat Fitness - Adrenaline Blue Level Benefits
							</h1>
						</div>
						<p className="text-lg sm:text-xl md:text-2xl text-gray-300 leading-relaxed animate-fade-in-up">
							Provides a comprehensive analysis of Adrenaline MMA's Blue Level
							badge system, a bodyweight and calisthenics program that
							incorporates MMA techniques into a high intensity Combat Fitness
							program.
						</p>
					</div>
				</div>

				{/* Scroll Indicator */}
				<div
					className="absolute bottom-8 left-1/2 transform -translate-x-1/2 
                    animate-bounce hidden md:block">
					<FontAwesomeIcon
						icon={faChevronDown}
						className="text-primary-light text-xl md:text-2xl"
					/>
				</div>
			</section>

			{/* Benefits Section */}
			<section className="py-20 bg-zinc-900">
				<div className="container mx-auto px-6">
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
							<div className="space-y-4">
								<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
									Benefits
								</h2>
								<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
									of MMA-Style Fitness
								</h3>
								<p className="text-gray-300 text-lg leading-relaxed">
									Mixed Martial Arts (MMA) has evolved into a popular and
									demanding combat sport, requiring a high level of fitness,
									strength, and technical skill. MMA-style fitness programs have
									gained popularity as a way to train for MMA competition and
									improve overall fitness. This article provides a comprehensive
									analysis of Adrenaline MMA's Blue Level badge system, a
									bodyweight and calisthenics program that incorporates MMA
									techniques. We will examine the scientific evidence supporting
									the effectiveness of this fitness program for both MMA
									training and general fitness goals.
								</p>
							</div>
						</div>

						{/* Benefits Grid */}
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 lg:gap-16">
							{/* Timeline Content */}
							<div className="relative order-2 lg:order-1">
								{/* Main vertical line */}
								<div
									className="absolute left-[28px] sm:left-[35px] top-0 bottom-0 w-[2px] md:w-[3px] 
                                  bg-gradient-to-b from-zinc-800 via-primary/20 to-zinc-800"
								/>

								{/* Benefits Timeline */}
								<div className="space-y-8">
									{benefitsData.map((benefit, index) => (
										<div
											key={index}
											className="relative pl-[70px] sm:pl-[90px] group 
                                          transform transition-transform duration-300 hover:-translate-y-1">
											{/* Number indicator */}
											<div className="absolute left-0 top-0">
												<div
													className="w-[56px] h-[56px] sm:w-[72px] sm:h-[72px] 
                                                  bg-zinc-900/80 backdrop-blur-sm
                                                  border-2 border-zinc-800 
                                                  group-hover:border-primary
                                                  transition-all duration-300
                                                  group-hover:shadow-[0_0_20px_rgba(var(--primary-rgb),0.3)]
                                                  flex items-center justify-center
                                                  shadow-lg shadow-black/20">
													<span
														className="text-2xl sm:text-3xl font-bold 
                                                       bg-gradient-to-br from-primary-light to-primary-DEFAULT 
                                                       bg-clip-text text-transparent">
														{benefit.number}
													</span>
												</div>
											</div>

											{/* Horizontal connector */}
											<div
												className="absolute left-[56px] sm:left-[72px] top-[27px] sm:top-[35px] 
                                              w-[14px] sm:w-[18px] h-[2px] sm:h-[3px] 
                                              bg-zinc-800 group-hover:bg-primary/50 
                                              transition-colors duration-300"
											/>

											{/* Content Card */}
											<div
												className="bg-zinc-900/40 backdrop-blur-md 
                                              border border-zinc-800/50 
                                              group-hover:border-zinc-700
                                              transition-all duration-300
                                              p-6 sm:p-8 rounded-xl
                                              shadow-xl shadow-black/10
                                              group-hover:shadow-2xl group-hover:shadow-primary/5">
												<h4
													className="text-xl sm:text-2xl md:text-3xl font-bold 
                                                 bg-gradient-to-r from-primary-light to-primary 
                                                 bg-clip-text text-transparent mb-4">
													{benefit.name}
												</h4>
												<p className="text-gray-300 text-base sm:text-lg leading-relaxed">
													{benefit.description}
												</p>
											</div>
										</div>
									))}
								</div>
							</div>

							{/* Image Side */}
							<div className="order-1 lg:order-2">
								<div className="lg:sticky lg:top-8 w-full">
									<div
										className="relative aspect-[4/5] rounded-xl overflow-hidden
                                      shadow-2xl shadow-black/20 
                                      transform transition-transform duration-500 hover:scale-[1.02]">
										<img
											src="/static/mma/img1.png"
											alt="MMA Style Fitness Benefits"
											className="w-full h-full object-cover brightness-90
                                          transition-all duration-500 hover:brightness-100"
											loading="eager"
										/>
										<div
											className="absolute inset-0 bg-gradient-to-t 
                                          from-black/70 via-black/20 to-transparent"
										/>

										{/* Optional: Add image overlay content */}
										<div className="absolute bottom-6 left-6 right-6">
											<div className="bg-black/60 backdrop-blur-sm p-4 rounded-lg">
												<h4 className="text-primary-light font-semibold mb-1">
													Transform Your Fitness Journey
												</h4>
												<p className="text-sm text-gray-300">
													Experience the complete MMA training system
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Effectiveness Section - Image Left */}
			<section className="py-16 bg-black">
				<div className="container mx-auto px-6">
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
							<div className="space-y-4">
								<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
									Effectiveness
								</h2>
								<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
									of Bodyweight and Calisthenics Training
								</h3>
								<p className="text-gray-300 text-lg leading-relaxed">
									Bodyweight exercises and calisthenics form the foundation of
									many MMA-style fitness programs, including Adrenaline MMA's
									Blue Level badge system. These exercises use body weight as
									resistance to build strength, endurance, and functional
									fitness. Research supports the effectiveness of bodyweight and
									calisthenics training for improving various aspects of
									fitness.
								</p>
							</div>
						</div>

						{/* Content Grid */}
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 lg:gap-16">
							{/* Image Side */}
							<div className="order-2 lg:order-1">
								<div className="lg:sticky lg:top-8 w-full">
									<div
										className="relative aspect-[4/5] rounded-xl overflow-hidden
                                      shadow-2xl shadow-black/20 
                                      transform transition-transform duration-500 hover:scale-[1.02]">
										<img
											src="/static/mma/tp-14.jpg"
											alt="Bodyweight and Calisthenics Training"
											className="w-full h-full object-cover brightness-90
                                         transition-all duration-500 hover:brightness-100"
											loading="eager"
										/>
										<div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent" />

										{/* Image Overlay Content */}
										<div className="absolute bottom-6 left-6 right-6">
											<div className="bg-black/60 backdrop-blur-sm p-4 rounded-lg">
												<h4 className="text-primary-light font-semibold mb-1">
													Proven Training Methods
												</h4>
												<p className="text-sm text-gray-300">
													Science-backed bodyweight exercises for optimal
													results
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Content Side */}
							<div className="order-1 lg:order-2">
								<div className="space-y-6">
									{[
										{
											title: "Muscle Strength and Endurance",
											description:
												"Progressive overload through advanced variations and increased repetitions",
										},
										{
											title: "Cardiovascular Fitness",
											description:
												"High-intensity bodyweight circuits and complex movement patterns",
										},
										{
											title: "Flexibility and Mobility",
											description:
												"Dynamic movements that improve range of motion and joint health",
										},
										{
											title: "Functional Fitness",
											description:
												"Natural movements that translate to real-world activities and sports",
										},
									].map((item, index) => (
										<div
											key={index}
											className="bg-zinc-900/40 backdrop-blur-md 
                                          border border-zinc-800/50 
                                          hover:border-zinc-700
                                          transition-all duration-300
                                          p-6 rounded-xl
                                          shadow-xl shadow-black/10
                                          hover:shadow-2xl hover:shadow-primary/5
                                          transform hover:-translate-y-1">
											<h3
												className="text-xl font-bold 
                                             bg-gradient-to-r from-primary-light to-primary 
                                             bg-clip-text text-transparent mb-3">
												{item.title}
											</h3>
											<p className="text-gray-300 text-base leading-relaxed">
												{item.description}
											</p>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* MMA Techniques Section - Image Right */}
			<section className="py-20 bg-zinc-900">
				<div className="container mx-auto px-6">
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
							<div className="space-y-4">
								<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
									MMA Techniques
								</h2>
								<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
									Integration into Fitness Programs
								</h3>
								<p className="text-gray-300 text-lg leading-relaxed">
									Incorporating MMA techniques into a fitness program provides
									several benefits beyond the physical advantages of bodyweight
									and calisthenics training. These techniques enhance both
									physical and mental aspects of fitness training.
								</p>
							</div>
						</div>

						{/* Content Grid */}
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 lg:gap-16">
							{/* Content Side */}
							<div className="order-2 lg:order-1">
								{/* Key Benefits Grid */}
								<div className="space-y-8">
									<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
										{[
											"Improved coordination and balance",
											"Enhanced mental focus",
											"Increased self-confidence",
											"Stress relief",
										].map((benefit, index) => (
											<div
												key={index}
												className="group bg-zinc-900/40 backdrop-blur-md 
                                             border border-zinc-800/50 
                                             hover:border-primary/30
                                             transition-all duration-300
                                             p-4 rounded-xl
                                             transform hover:-translate-y-1
                                             hover:shadow-lg hover:shadow-primary/5">
												<div className="flex items-center gap-4">
													<div
														className="bg-zinc-800/80 rounded-lg p-3 
                                                    group-hover:bg-primary/10 
                                                    transition-colors duration-300">
														<span
															className="text-xl font-bold 
                                                         bg-gradient-to-br from-primary-light to-primary 
                                                         bg-clip-text text-transparent">
															{index + 1}
														</span>
													</div>
													<span className="text-gray-300 font-medium group-hover:text-primary-light transition-colors duration-300">
														{benefit}
													</span>
												</div>
											</div>
										))}
									</div>

									{/* Training Details */}
									<div
										className="bg-zinc-900/40 backdrop-blur-md 
                                      border border-zinc-800/50 
                                      p-6 rounded-xl
                                      hover:border-zinc-700
                                      transition-all duration-300
                                      shadow-xl shadow-black/10">
										<h3
											className="text-2xl font-bold 
                                         bg-gradient-to-r from-primary-light to-primary 
                                         bg-clip-text text-transparent mb-4">
											Punching Drills and Level Changes
										</h3>
										<p className="text-gray-300 text-lg leading-relaxed">
											The Blue Level badge system includes punching drills
											performed at different levels (full standing, crouch, and
											low) to improve level changing and hip movement. This
											emphasizes developing proper technique and coordination
											for striking while incorporating dynamic movements to
											challenge balance and leg strength.
										</p>
									</div>
								</div>
							</div>

							{/* Image Side */}
							<div className="order-1 lg:order-2">
								<div className="lg:sticky lg:top-8 w-full">
									<div
										className="relative aspect-[4/5] rounded-xl overflow-hidden
                                      shadow-2xl shadow-black/20 
                                      transform transition-transform duration-500 hover:scale-[1.02]">
										<img
											src="/static/mma/tp-12.png"
											alt="MMA Techniques Training"
											className="w-full h-full object-cover brightness-90
                                         transition-all duration-500 hover:brightness-100"
											loading="eager"
										/>
										<div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent" />

										{/* Image Overlay Content */}
										<div className="absolute bottom-6 left-6 right-6">
											<div className="bg-black/60 backdrop-blur-sm p-4 rounded-lg">
												<h4 className="text-primary-light font-semibold mb-1">
													Advanced Training Techniques
												</h4>
												<p className="text-sm text-gray-300">
													Master essential MMA movements and combinations
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="py-20 bg-black">
				<div className="container mx-auto px-6">
					<div className="mx-auto">
						{/* Section Header */}
						<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
							<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
							<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
							<div className="space-y-4">
								<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
									Research Validation
								</h2>
								<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
									Scientific Backing of the Blue Level Badge System
								</h3>
								<p className="text-gray-300 text-lg leading-relaxed">
									The Blue Level badge system is built on established scientific
									principles and research in exercise science, sports
									performance, and skill acquisition. This evidence-based
									approach ensures optimal training outcomes.
								</p>
							</div>
						</div>

						{/* Content Grid */}
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 lg:gap-16">
							{/* Image Side */}
							<div className="order-2 lg:order-1">
								<div className="lg:sticky lg:top-8 w-full">
									<div
										className="relative aspect-[4/5] rounded-xl overflow-hidden
                                      shadow-2xl shadow-black/20 
                                      transform transition-transform duration-500 hover:scale-[1.02]">
										<img
											src="/static/mma/tp-13.jpg"
											alt="Blue Level Badge Research"
											className="w-full h-full object-cover brightness-90
                                         transition-all duration-500 hover:brightness-100"
											loading="eager"
										/>
										<div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent" />

										{/* Image Overlay Content */}
										<div className="absolute bottom-6 left-6 right-6">
											<div className="bg-black/60 backdrop-blur-sm p-4 rounded-lg">
												<div className="flex items-center gap-4 mb-2">
													<div className="p-3 bg-primary-light/10 rounded-lg backdrop-blur-sm">
														<FontAwesomeIcon
															icon={faChartLine}
															className="text-2xl text-primary-light"
														/>
													</div>
													<h4 className="text-primary-light font-semibold">
														Research-Backed Training
													</h4>
												</div>
												<p className="text-sm text-gray-300">
													Evidence-based methods for optimal results
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							{/* Content Side */}
							<div className="order-1 lg:order-2">
								<div className="space-y-6">
									{[
										{
											title: "Exercise Science",
											content:
												"The Blue Level badge system incorporates proven exercise principles from sports science research, including progressive overload and functional movement patterns.",
										},
										{
											title: "Performance Metrics",
											content:
												"Training methods are aligned with established performance indicators for martial arts and combat sports, ensuring effective skill development.",
										},
										{
											title: "Adaptation Principles",
											content:
												"The program follows scientifically-validated principles of physical adaptation and skill acquisition, optimizing learning and progress.",
										},
									].map((item, index) => (
										<div
											key={index}
											className="bg-zinc-900/40 backdrop-blur-md 
                                          border border-zinc-800/50 
                                          hover:border-zinc-700
                                          transition-all duration-300
                                          p-6 rounded-xl
                                          shadow-xl shadow-black/10
                                          hover:shadow-2xl hover:shadow-primary/5
                                          transform hover:-translate-y-1">
											<h3
												className="text-xl font-bold 
                                             bg-gradient-to-r from-primary-light to-primary 
                                             bg-clip-text text-transparent mb-3">
												{item.title}
											</h3>
											<p className="text-gray-300 text-base leading-relaxed">
												{item.content}
											</p>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Evidence Section */}
			<section className="py-20 bg-zinc-900 relative overflow-hidden">
				{/* Background Design Elements */}
				<div className="absolute inset-0 opacity-10">
					<div className="absolute top-0 left-0 w-96 h-96 bg-primary-light/20 rounded-full blur-3xl" />
					<div className="absolute bottom-0 right-0 w-96 h-96 bg-primary-dark/20 rounded-full blur-3xl" />
				</div>

				<div className="container mx-auto px-6 relative">
					{/* Section Header */}
					<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
						<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<div className="space-y-4">
							<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
								Evidence & Research
							</h2>
							<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								Supporting the Blue Level Badge System
							</h3>
							<p className="text-gray-300 text-lg leading-relaxed">
								While specific details about the Blue Level badge system are
								unavailable, the exercises and techniques commonly found in
								MMA-style fitness programs have strong scientific backing.
								Research suggests that these exercises and techniques can
								improve:
							</p>
						</div>
					</div>

					{/* Evidence Grid */}
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
						{evidenceData.map((item, index) => (
							<div
								key={index}
								className="group relative transform transition-transform duration-300 hover:-translate-y-1">
								{/* Hover Background Effect */}
								<div
									className="absolute inset-0 bg-gradient-to-r from-primary-light/10 to-primary-dark/10 
                                  rounded-xl transform scale-[0.98] opacity-0 
                                  group-hover:scale-100 group-hover:opacity-100 
                                  transition-all duration-300"
								/>

								{/* Content Card */}
								<div
									className="relative bg-zinc-900/40 backdrop-blur-md 
                                  border border-zinc-800/50 
                                  group-hover:border-primary/30
                                  transition-all duration-300
                                  p-6 sm:p-8 rounded-xl
                                  shadow-xl shadow-black/10
                                  group-hover:shadow-2xl group-hover:shadow-primary/5">
									{/* Card Header */}
									<div className="mb-6 flex items-start gap-4">
										<div
											className="w-12 h-12 bg-gradient-to-br from-primary-light/20 to-primary-dark/20 
                                          rounded-lg flex items-center justify-center
                                          group-hover:from-primary-light/30 group-hover:to-primary-dark/30
                                          transition-all duration-300">
											<span
												className="text-2xl font-bold 
                                               bg-gradient-to-br from-primary-light to-primary 
                                               bg-clip-text text-transparent">
												{index + 1}
											</span>
										</div>
										<h3
											className="text-xl font-bold 
                                         bg-gradient-to-r from-primary-light to-primary 
                                         bg-clip-text text-transparent
                                         group-hover:from-primary-light group-hover:to-primary
                                         transition-colors duration-300">
											{item.title}
										</h3>
									</div>

									{/* Card Content */}
									<p className="text-gray-300 text-base leading-relaxed pl-16">
										{item.description}
									</p>
								</div>
							</div>
						))}
					</div>

					{/* Bottom Decoration */}
					<div className="mt-16 flex justify-center">
						<div className="h-1 w-24 bg-gradient-to-r from-primary-light/50 to-primary-dark/50 rounded-full" />
					</div>
				</div>
			</section>

			{/* Testimonials Section */}
			<section className="py-20 bg-black">
				<div className="container mx-auto px-6">
					{/* Section Header */}
					<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
						<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<div className="space-y-4">
							<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
								Success Stories
							</h2>
							<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								Testimonials and Case Studies
							</h3>
							<p className="text-gray-300 text-lg leading-relaxed">
								Adrenaline MMA's Blue Level badge system provides numerous
								success stories highlighting the effectiveness of MMA-style
								fitness programs. Individuals who have incorporated MMA training
								into their fitness routines report significant improvements in:
							</p>
						</div>
					</div>

					{/* Testimonials Grid */}
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
						{[
							{
								name: "Weight Loss and Body Composition",
								number: "1",
								content:
									"One individual reported losing 115 pounds by incorporating MMA training into their fitness routine.",
							},
							{
								name: "Strength and Endurance",
								number: "2",
								content:
									"One individual shared feeling physically fitter and more powerful than ever before after three months of MMA training.",
							},
							{
								name: "Mental Focus and Discipline",
								number: "3",
								content:
									"One individual described how incorporating Conor McGregor's daily routine, which includes MMA training and mental focus exercises, helped them feel more focused, energized, and disciplined.",
							},
						].map((testimonial, index) => (
							<div
								key={index}
								className="group bg-zinc-900/40 backdrop-blur-md 
                             border border-zinc-800/50 
                             hover:border-primary/30
                             transition-all duration-300
                             p-6 rounded-xl
                             shadow-xl shadow-black/10
                             hover:shadow-2xl hover:shadow-primary/5
                             transform hover:-translate-y-1">
								<div className="flex items-center gap-4 mb-6">
									<div
										className="w-12 h-12 bg-gradient-to-br from-primary-light/20 to-primary-dark/20 
                                      rounded-lg flex items-center justify-center
                                      group-hover:from-primary-light/30 group-hover:to-primary-dark/30
                                      transition-all duration-300">
										<span
											className="text-2xl font-bold 
                                           bg-gradient-to-br from-primary-light to-primary 
                                           bg-clip-text text-transparent">
											{testimonial.number}
										</span>
									</div>
									<h3
										className="text-xl font-bold 
                                     bg-gradient-to-r from-primary-light to-primary 
                                     bg-clip-text text-transparent
                                     group-hover:from-primary-light group-hover:to-primary
                                     transition-colors duration-300">
										{testimonial.name}
									</h3>
								</div>
								<p className="text-gray-300 text-base leading-relaxed pl-16">
									{testimonial.content}
								</p>
							</div>
						))}
					</div>

					{/* Bottom Decoration */}
					<div className="mt-16 flex justify-center">
						<div className="h-1 w-24 bg-gradient-to-r from-primary-light/50 to-primary-dark/50 rounded-full" />
					</div>
				</div>
			</section>

			{/* Why It Works - Image Right */}
			<section className="py-20 bg-zinc-900 relative overflow-hidden">
				<div className="container mx-auto px-6">
					{/* Section Header */}
					<div className="relative mb-12 md:mb-20 pl-4 md:pl-5 border-l-4 border-primary">
						<span className="absolute -left-2 top-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<span className="absolute -left-2 bottom-0 w-3 h-3 bg-primary-light rounded-full animate-pulse" />
						<div className="space-y-4">
							<h2 className="text-4xl font-bold bg-clip-text text-white bg-gradient-to-r from-primary to-primary-light">
								Why It Works
							</h2>
							<h3 className="text-md italic sm:text-lg md:text-xl lg:text-2xl text-primary-light font-medium tracking-wide">
								The Science Behind Our Training System
							</h3>
							<p className="text-gray-300 text-lg leading-relaxed">
								Our training methodology is built on proven principles and
								extensive research, ensuring optimal results for practitioners
								at every level.
							</p>
						</div>
					</div>

					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-12 lg:gap-16">
						{/* Content Side */}
						<div className="order-2 lg:order-1">
							<div className="space-y-6">
								{whyItWorksData.map((section, index) => (
									<div
										key={index}
										className="group bg-zinc-900/40 backdrop-blur-md 
                                     border border-zinc-800/50 
                                     hover:border-primary/30
                                     transition-all duration-300
                                     p-6 rounded-xl
                                     shadow-xl shadow-black/10
                                     hover:shadow-2xl hover:shadow-primary/5
                                     transform hover:-translate-y-1">
										<div className="flex items-start gap-4 mb-4">
											<div
												className="w-12 h-12 bg-gradient-to-br from-primary-light/20 to-primary-dark/20 
                                              rounded-lg flex items-center justify-center shrink-0
                                              group-hover:from-primary-light/30 group-hover:to-primary-dark/30
                                              transition-all duration-300">
												<span
													className="text-2xl font-bold 
                                                   bg-gradient-to-br from-primary-light to-primary 
                                                   bg-clip-text text-transparent">
													{index + 1}
												</span>
											</div>
											<h3
												className="text-xl font-bold 
                                             bg-gradient-to-r from-primary-light to-primary 
                                             bg-clip-text text-transparent">
												{section.title}
											</h3>
										</div>
										<p className="text-gray-300 text-base leading-relaxed pl-16">
											{section.content}
										</p>
									</div>
								))}
							</div>
						</div>

						{/* Image Side */}
						<div className="order-1 lg:order-2">
							<div className="lg:sticky lg:top-8 w-full">
								<div
									className="relative aspect-[4/5] rounded-xl overflow-hidden
                                  shadow-2xl shadow-black/20 
                                  transform transition-transform duration-500 hover:scale-[1.02]">
									<img
										src="/static/mma/tp-15.jpg"
										alt="MMA Training Effectiveness"
										className="w-full h-full object-cover brightness-90
                                     transition-all duration-500 hover:brightness-100"
										loading="eager"
									/>
									<div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent" />

									{/* Image Overlay Content */}
									<div className="absolute bottom-6 left-6 right-6">
										<div className="bg-black/60 backdrop-blur-sm p-4 rounded-lg">
											<div className="flex items-center gap-4 mb-2">
												<div className="p-3 bg-primary-light/10 rounded-lg backdrop-blur-sm">
													<FontAwesomeIcon
														icon={faCheckCircle}
														className="text-2xl text-primary-light"
													/>
												</div>
												<h4 className="text-primary-light font-semibold">
													Proven Results
												</h4>
											</div>
											<p className="text-sm text-gray-300">
												Scientific evidence supports the effectiveness of
												MMA-style training methods
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Footer */}
			<footer className="bg-black py-12">
				<div className="container mx-auto px-6">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="text-gray-400 mb-6 md:mb-0">
							&copy; 2025 Adrenaline MMA. All rights reserved.
						</div>
						<div className="flex space-x-8">
							<Link
								to="https://www.facebook.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faFacebook} size="lg" />
							</Link>
							{/* <Link
								to="https://www.twitter.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faTwitter} size="lg" />
							</Link> */}
							<Link
								to="https://www.instagram.com/adrenalinemmahome/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-gray-400 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faInstagram} size="lg" />
							</Link>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default BlueLevelBadgeBenefits;
